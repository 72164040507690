import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDateFormatter } from '@react-aria/i18n';
import { getLocalTimeZone } from '@internationalized/date';
import EaseIn from '../../../Components/EaseIn';

type Props = {
  name: string;
  icon: any;
  range: any;
};
export default function ConfirmDetails({ name, icon, range }: Props) {
  const formatter = useDateFormatter({ dateStyle: 'long' });

  return (
    <EaseIn>
      <h1
        style={{
          fontSize: 22,
          textAlign: 'center',
          paddingBottom: 12,
        }}
      >
        Your details:
      </h1>
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 16,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
            height: 120,
            minWidth: 300,
            borderRadius: 5,
            backgroundColor: ' #579ABE',
          }}
        >
          <FontAwesomeIcon
            icon={icon.icon}
            color="white"
            style={{
              fontSize: 32,
              backgroundColor: 'black',
              borderRadius: 8,
              width: 30,
              height: 30,
              padding: 16,
              margin: 16,
            }}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              paddingLeft: 16,
              paddingRight: 16,
            }}
          >
            <div
              style={{
                fontWeight: `bold`,
                fontSize: 25,
                color: 'white',
                textAlign: 'right',
              }}
            >
              {name}
            </div>

            <p
              style={{
                color: 'white',
                textAlign: 'right',
              }}
            >
              {formatter.formatRange(
                range.start.toDate(getLocalTimeZone()),
                range.end.toDate(getLocalTimeZone()),
              )}
            </p>
          </div>
        </div>
      </div>
      <p
        style={{
          paddingTop: 16,
          paddingLeft: 8,
          paddingRight: 8,
          textAlign: 'center',
        }}
      >
        Is this correct?
      </p>
    </EaseIn>
  );
}
