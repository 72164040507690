import * as React from 'react';
import TextField from '@mui/material/TextField';
import EaseIn from '../../../Components/EaseIn';

type Props = {
  name: string;
  setName: (letter: string) => void;
  edit?: boolean;
};
export default function EventName({ edit, name, setName }: Props) {
  return (
    <EaseIn>
      {edit ? (
        <h1
          style={{
            fontSize: 22,
            textAlign: 'center',
          }}
        >
          Edit your event name
        </h1>
      ) : (
        <>
          <h1
            style={{
              fontSize: 22,
              textAlign: 'center',
            }}
          >
            Let's start with the basic information
          </h1>
          <p
            style={{
              fontSize: 17,
              textAlign: 'center',
            }}
          >
            Let us know what event you are planning.
          </p>
        </>
      )}
      <div
        style={{
          width: '100%',
          paddingTop: 32,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <TextField
          defaultValue={name}
          onChange={(e) => setName(e.target.value)}
          InputProps={{
            inputProps: {
              style: { textAlign: 'center' },
            },
          }}
          autoFocus
          fullWidth
          placeholder="Your Event Name"
          variant="standard"
        />
      </div>
    </EaseIn>
  );
}

EventName.defaultProps = {
  edit: false,
};
