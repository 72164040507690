import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import styled from 'styled-components';
import jwt from 'jwt-decode';
import { faTrashCan, faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Dashboard from '../../Containers/Dashboard';
import Text from '../../Components/Text/BaseText';
import DeviceQuery from '../../Utilities/DeviceQuery';
import AddEvent from './AddEvent';
import { getEvents, deleteEvent } from '../../Api/Calls';
import Icons from './Constants/Icons';
import Colors from './Constants/CalendarColors';
import AddTasks from './AddTask';
import MutatingDots from '../../Components/MutatingDots';
import EditEvent from './EditEvent';

const CalenderContainer = styled.div`
  border-radius: 12px;
  padding: 50px;
  background-color: white;
  min-height: 90%;
  height: 90%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 70%;
  zoom: 0.5;
  @media (${DeviceQuery.tablet}) {
    zoom: 0.65;
    min-height: 100%;
    height: 100%;
    width: 100%;
  }
`;

const EventContainer = styled.div`
  position: relative;
  flex-direction: column;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 50px;
  background-color: white;
  min-height: 90%;
  height: 90%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 22px;
  width: 28%;
  display: flex;
  justify-content: space-between;
  @media (${DeviceQuery.tablet}) {
    overflow: unset;
    margin-top: 32px;
    padding-bottom: 32px;
    min-height: unset;
    max-height: 50vh;
    height: unset;
    width: 100%;
  }
  @media (${DeviceQuery.mobile}) {
    margin-top: 32px;
    width: 100%;
  }
`;

const Root = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 24px;
  @media (${DeviceQuery.tablet}) {
    min-height: 100vh !important;
    height: 100vh;
    flex-direction: column;
    overflow-y: scroll;
  }
`;

const EventListContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(90vh - 180px);
  @media (${DeviceQuery.tablet}) {
    height: unset;
  }
`;

const EventListItem = styled.div`
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-top: 1px solid lightgrey;
  border-right: 1px solid lightgrey;
  border-left: 1px solid lightgrey;
  background-color: snow;
  padding: 8px;
  cursor: pointer;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
`;

const Loader = styled.div`
  display: flex;
  width: 90vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  @media (${DeviceQuery.tablet}) {
    zoom: 1;
    width: 100%;
    overflow: hidden;
    position: fixed;
    height: 90vh;
  }
`;

const AddEventButton = styled.div.attrs(
  (props: { darkMode: boolean }) => props,
)`
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 16px;
  cursor: pointer;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  background-image: ${(props) =>
    props.darkMode
      ? `linear-gradient(to right, #99376f, #803474)`
      : `linear-gradient(to right, #376099, #345f80)`};
  width: 80%;
  height: 44px;
  border-radius: 6px;
  text-align: center;
  @media (${DeviceQuery.tablet}) {
    position: unset;
    margin-top: 32px;
    width: 100%;
    bottom: 0px;
  }
  @media (${DeviceQuery.mobile}) {
    position: relative;
    bottom: 0px;
  }
`;

const Relative = styled.div`
  position: relative;
`;
/**
 *   {
    title: 'Otro',
    start: '2022-09-15T13:45:00-05:00',
    end: '2022-09-23T14:00:00-05:00',
    colorEvento: 'green',
    color: 'white',
  },
 */
function MyCalendar(props: any) {
  const [loading, setLoading] = React.useState(true);
  const [openEventModal, setOpenEventModal] = React.useState(false);
  const [openTasksModal, setOpenTasksModal] = React.useState({});
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [hover, onHover] = React.useState('');
  const [hoverDelete, onHoverDelete] = React.useState(null);
  const [hoverEdit, onHoverEdit] = React.useState(null);
  const [events, setEvents] = React.useState([]);
  const [deleteId, setDeleteId] = React.useState(null);
  const [editEvent, setEditEvent] = React.useState({});
  const token = localStorage.getItem('token');

  const onEdit = (
    id: number,
    title: string,
    dateTo: any,
    dateFrom: any,
    icon: any,
  ) => {
    setEditEvent({ id, title, dateTo, dateFrom, icon });
    setOpenTasksModal({});
    setOpenEventModal(false);
    setOpenEditModal(true);
  };

  const onDelete = async (id: number) => {
    setDeleteId(id);
    setOpenTasksModal({});
    const { rows } = await deleteEvent(id);
    setEvents(rows === null ? [] : rows);
    setDeleteId(null);
  };
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { user } = jwt(token);
  const { darkMode } = useSelector((state: any) => state.Config);

  const getData = async () => {
    const { rows } = await getEvents(user.id);
    setEvents(rows);
  };

  React.useEffect(() => {
    setLoading(true);
    getData();
    setLoading(false);
  }, [openEventModal]);

  const calendarEvents =
    events != null &&
    events.length > 0 &&
    events.map((event, i) => ({
      title: event.title,
      start: event.dateFrom,
      end:
        event.dateTo === event.dateFrom
          ? event.dateTo
          : moment(event.dateTo).add(1, 'days').format('YYYY-MM-DD'),
      color: Colors[i],
      allDay: true,
    }));

  if (loading) {
    return (
      <Dashboard>
        <Loader>
          <MutatingDots />
        </Loader>
      </Dashboard>
    );
  }
  return (
    <Dashboard>
      <AddEvent
        open={openEventModal}
        onClose={() => setOpenEventModal(false)}
      />
      <EditEvent
        event={editEvent}
        setEvents={setEvents}
        open={openEditModal}
        onClose={() => setOpenEditModal(false)}
      />
      <AddTasks
        event={openTasksModal}
        open={Object.keys(openTasksModal).length > 0}
        onClose={() => {
          onHover('');
          setOpenTasksModal({});
        }}
      />

      <Root>
        <CalenderContainer>
          <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin]}
            events={calendarEvents}
            height="100%"
          />
        </CalenderContainer>
        <EventContainer>
          <div>
            <Text
              style={{
                color: 'black',
                fontSize: 20,
                fontWeight: '500',
              }}
            >
              Next Events
            </Text>
            <Relative>
              <div
                style={{
                  overflowY: 'scroll',
                }}
              >
                {(events === null || events.length === 0) && (
                  <div
                    style={{
                      paddingTop: 16,
                      display: 'flex',
                      height: '100%',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: 'grey',
                      fontSize: 16,
                    }}
                  >
                    no events added
                  </div>
                )}
                <EventListContainer>
                  {events != null &&
                    events.length > 0 &&
                    events.map(({ id, title, dateTo, dateFrom, icon }) => {
                      const eventIcon = Icons.filter((i) => i.name === icon);
                      return (
                        <>
                          <EventListItem
                            style={{
                              filter:
                                id === deleteId ? ` brightness(30%)` : 'unset',
                              pointerEvents: id === deleteId ? 'none' : 'auto',
                            }}
                            onMouseOver={() => onHover(id)}
                            onMouseOut={() => onHover('')}
                            onClick={() =>
                              setOpenTasksModal({
                                id,
                                title,
                                dateFrom,
                                dateTo,
                              })
                            }
                          >
                            {id === deleteId && (
                              <div
                                style={{
                                  position: 'absolute',
                                  marginLeft: 'auto',
                                  marginRight: 'auto',
                                  top: 20,
                                  bottom: 0,
                                  right: 10,
                                  textAlign: 'center',
                                  zoom: 0.75,
                                }}
                              >
                                <MutatingDots />
                              </div>
                            )}
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <FontAwesomeIcon
                                icon={eventIcon[0].icon}
                                color={hover === id ? 'black' : '#68a6bb'}
                                style={{
                                  fontSize: 12,
                                  backgroundColor:
                                    hover === id ? '#e29e43' : 'black',
                                  borderRadius: 8,
                                  width: 25,
                                  height: 25,
                                  padding: 16,
                                  marginRight: 16,
                                }}
                              />
                              <div>
                                <div
                                  style={{
                                    fontWeight: hover === id ? 'bold' : '500',
                                    fontSize: 18,
                                  }}
                                >
                                  {title}
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    fontSize: 12,
                                  }}
                                >
                                  {dateTo === dateFrom ? (
                                    <div>{moment(dateFrom).format('ll')}</div>
                                  ) : (
                                    <div>
                                      {moment(dateFrom).format('MMM Do')} -{' '}
                                      {moment(dateTo).format('MMM Do, YYYY')}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div
                              style={{
                                position: 'relative',
                              }}
                            />
                          </EventListItem>
                          <div
                            style={{
                              borderBottom: `1px solid lightgrey`,
                              borderRight: `1px solid lightgrey`,
                              borderLeft: `1px solid lightgrey`,
                              backgroundColor: '#f2e7e7',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'flex-end',
                              marginBottom: 16,
                              borderBottomRightRadius: 4,
                              borderBottomLeftRadius: 4,
                            }}
                          >
                            <FontAwesomeIcon
                              onMouseEnter={() => onHoverEdit(id)}
                              onMouseLeave={() => onHoverEdit('')}
                              onClick={() =>
                                onEdit(id, title, dateTo, dateFrom, icon)
                              }
                              icon={faPenToSquare}
                              color={hoverEdit === id ? 'blue' : 'black'}
                              style={{
                                cursor: 'pointer',
                                fontSize: 16,
                                padding: 10,
                                marginRight: 8,
                              }}
                            />
                            <FontAwesomeIcon
                              onMouseEnter={() => onHoverDelete(id)}
                              onMouseLeave={() => onHoverDelete('')}
                              onClick={() => onDelete(id)}
                              icon={faTrashCan}
                              color={hoverDelete === id ? 'blue' : 'black'}
                              style={{
                                cursor: 'pointer',
                                fontSize: 16,
                                padding: 10,
                                marginRight: 8,
                              }}
                            />
                          </div>
                        </>
                      );
                    })}
                </EventListContainer>
              </div>
            </Relative>
            <AddEventButton onClick={() => setOpenEventModal(true)}>
              <Text
                style={{
                  color: 'white',
                  fontSize: 16,
                }}
              >
                Add an Event
              </Text>
            </AddEventButton>
          </div>
        </EventContainer>
      </Root>
    </Dashboard>
  );
}

export default MyCalendar;
