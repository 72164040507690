import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import styled from 'styled-components';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { parseDate, getLocalTimeZone } from '@internationalized/date';
import moment from 'moment';
import jwt from 'jwt-decode';
import useWindowDimensions from '../../Utilities/WindowDetection';
import EventName from './Stepper/EventName';
import DateSelector from './Stepper/DateSelector';
import SelectIcon, { icons } from './Stepper/SelectIcon';
import ConfirmDetails from './Stepper/ConfirmDetails';
import { updateEvent } from '../../Api/Calls';

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  //   bgcolor: 'white',
  border: '2px solid #ffffff0',
  //   boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  borderRadius: 2,
  padding: 2,
};

const Button = styled.button<{
  $buttonColor: string;
  $disabled: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 50px;
  background-color: ${(props) =>
    props.$disabled ? `#e8e7f2` : props.$buttonColor};
  border-radius: 4px;
  border: 0rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`;

const ButtonOutline = styled.button<{
  $disabled: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 50px;
  background-color: white;
  border-radius: 4px;
  border: 1px solid blue;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`;

const steps = ['Name', 'Date', 'Icon', 'Confirm'];

type Props = {
  open: boolean;
  onClose: () => void;
  event: any;
  setEvents: (events: any) => void;
};
export default function EditEvent({ event, setEvents, open, onClose }: Props) {
  if (!open) {
    return null;
  }
  const { id, title, icon: selectedIcon, dateFrom, dateTo } = event;

  const selectedIconObject = icons.filter(
    (icon) => selectedIcon === icon.name,
  )[0];

  const { isMobile } = useWindowDimensions();
  const startDate = moment().format('YYYY-MM-DD');
  const [activeStep, setActiveStep] = React.useState(0);
  const token = localStorage.getItem('token');
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { user } = jwt(token);

  const [name, setName] = React.useState('');

  /**
   * date selection
   */
  const [range, setRange] = React.useState({
    start: parseDate(startDate),
    end: parseDate(startDate),
  });

  const [icon, setIcon] = React.useState(null);

  React.useEffect(() => {
    setName(title);
    setIcon(selectedIconObject);
    setRange({
      start: parseDate(dateFrom),
      end: parseDate(dateTo),
    });
  }, [event, open]);

  const disableNext = () => {
    switch (activeStep) {
      case 0:
        return !(name.length > 0);
      case 1:
        return false;
        break;
      case 2:
        return !icon;
        break;
      default:
        return false;
    }
  };

  const handleNext = async () => {
    if (activeStep === 3) {
      const { name: iconName } = icon;
      const { rows } = await updateEvent(
        id,
        name,
        moment(range.start.toDate(getLocalTimeZone())).format('YYYY-MM-DD'),
        moment(range.end.toDate(getLocalTimeZone())).format('YYYY-MM-DD'),
        iconName,
      );
      setEvents(rows);
      onClose();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box
        sx={{
          ...style,
          height: isMobile ? '100%' : '100vh',
          width: isMobile ? '100%' : '70%',
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            height: 60,
          }}
        />
        <div
          style={{
            top: 46,
            width: '80%',
            left: '50%',
            transform: 'translate(-50%, 0%)',
            position: 'absolute',
            boxShadow: `rgba(0, 0, 0, 0.35) 0px 5px 15px`,
            backgroundImage: `repeating-linear-gradient(145.47deg, #bfe4ff 24%, #eaeff2 5%, #ceceff 55.07%, #dfd1f9 77%, #e6e3f5 93%, #eaeff2 100%)`,
            borderRadius: 8,
            padding: 16,
            zIndex: 9999,
          }}
        >
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            borderRadius: 10,
            height: '80%',
            position: 'relative',
            backgroundColor: 'white',
            boxShadow: `rgba(0, 0, 0, 0.35) 0px 5px 15px`,
          }}
        >
          {activeStep === 0 && (
            <EventName edit setName={setName} name={event.title} />
          )}
          {activeStep === 1 && (
            <DateSelector range={range} setRange={setRange} />
          )}
          {activeStep === 2 && <SelectIcon icon={icon} setIcon={setIcon} />}
          {activeStep === 3 && (
            <ConfirmDetails name={name} range={range} icon={icon} />
          )}
          <div
            style={{
              position: 'absolute',
              bottom: 20,
              right: 20,
              display: 'flex',
            }}
          >
            {activeStep === 0 ? (
              <ButtonOutline
                onClick={() => onClose()}
                $disabled={false}
                style={{
                  marginRight: 8,
                }}
              >
                <div>Cancel</div>
              </ButtonOutline>
            ) : (
              <ButtonOutline
                onClick={handleBack}
                $disabled={false}
                style={{
                  marginRight: 8,
                }}
              >
                <div>Back</div>
              </ButtonOutline>
            )}

            <Button
              onClick={disableNext() ? () => null : () => handleNext()}
              $disabled={disableNext()}
              $buttonColor="#bae1ff"
            >
              <div>{activeStep === 3 ? 'Confirm' : 'Next'}</div>
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
}
