const colors = [
  '#ffb3ba',
  '#ffdfba',
  '#ffffba',
  '#baffc9',
  '#bae1ff',
  '#ffb3ba',
  '#ffdfba',
  '#ffffba',
  '#baffc9',
  '#bae1ff',
  '#ffb3ba',
  '#ffdfba',
  '#ffffba',
  '#baffc9',
  '#bae1ff',
  '#ffb3ba',
  '#ffdfba',
  '#ffffba',
  '#baffc9',
  '#bae1ff',
  '#ffb3ba',
  '#ffdfba',
  '#ffffba',
  '#baffc9',
  '#bae1ff',
  '#ffb3ba',
  '#ffdfba',
  '#ffffba',
  '#baffc9',
  '#bae1ff',
  '#ffb3ba',
  '#ffdfba',
  '#ffffba',
  '#baffc9',
  '#bae1ff',
  '#ffb3ba',
  '#ffdfba',
  '#ffffba',
  '#baffc9',
  '#bae1ff',
  '#ffb3ba',
  '#ffdfba',
  '#ffffba',
  '#baffc9',
  '#bae1ff',
  '#ffb3ba',
  '#ffdfba',
  '#ffffba',
  '#baffc9',
  '#bae1ff',
  '#ffb3ba',
  '#ffdfba',
  '#ffffba',
  '#baffc9',
  '#bae1ff',
  '#ffb3ba',
  '#ffdfba',
  '#ffffba',
  '#baffc9',
  '#bae1ff',
  '#ffb3ba',
  '#ffdfba',
  '#ffffba',
  '#baffc9',
  '#bae1ff',
  '#ffb3ba',
  '#ffdfba',
  '#ffffba',
  '#baffc9',
  '#bae1ff',
  '#ffb3ba',
  '#ffdfba',
  '#ffffba',
  '#baffc9',
  '#bae1ff',
  '#ffb3ba',
  '#ffdfba',
  '#ffffba',
  '#baffc9',
  '#bae1ff',
  '#ffb3ba',
  '#ffdfba',
  '#ffffba',
  '#baffc9',
  '#bae1ff',
  '#ffb3ba',
  '#ffdfba',
  '#ffffba',
  '#baffc9',
  '#bae1ff',
  '#ffb3ba',
  '#ffdfba',
  '#ffffba',
  '#baffc9',
  '#bae1ff',
  '#ffb3ba',
  '#ffdfba',
  '#ffffba',
  '#baffc9',
  '#bae1ff',
];

const darkColors = [
  '#a62929',
  '#9d6b04',
  '#7f890d',
  '#9d6b04',
  '#3f42ba',
  '#a62929',
  '#9d6b04',
  '#7f890d',
  '#9d6b04',
  '#3f42ba',
  '#a62929',
  '#9d6b04',
  '#7f890d',
  '#9d6b04',
  '#3f42ba',
  '#a62929',
  '#9d6b04',
  '#7f890d',
  '#9d6b04',
  '#3f42ba',
  '#a62929',
  '#9d6b04',
  '#7f890d',
  '#9d6b04',
  '#3f42ba',
  '#a62929',
  '#9d6b04',
  '#7f890d',
  '#9d6b04',
  '#3f42ba',
  '#a62929',
  '#9d6b04',
  '#7f890d',
  '#9d6b04',
  '#3f42ba',
  '#a62929',
  '#9d6b04',
  '#7f890d',
  '#9d6b04',
  '#3f42ba',
  '#a62929',
  '#9d6b04',
  '#7f890d',
  '#9d6b04',
  '#3f42ba',
  '#a62929',
  '#9d6b04',
  '#7f890d',
  '#9d6b04',
  '#3f42ba',
  '#a62929',
  '#9d6b04',
  '#7f890d',
  '#9d6b04',
  '#3f42ba',
  '#a62929',
  '#9d6b04',
  '#7f890d',
  '#9d6b04',
  '#3f42ba',
  '#a62929',
  '#9d6b04',
  '#7f890d',
  '#9d6b04',
  '#3f42ba',
  '#a62929',
  '#9d6b04',
  '#7f890d',
  '#9d6b04',
  '#3f42ba',
  '#a62929',
  '#9d6b04',
  '#7f890d',
  '#9d6b04',
  '#3f42ba',
  '#a62929',
  '#9d6b04',
  '#7f890d',
  '#9d6b04',
  '#3f42ba',
  '#a62929',
  '#9d6b04',
  '#7f890d',
  '#9d6b04',
  '#3f42ba',
  '#a62929',
  '#9d6b04',
  '#7f890d',
  '#9d6b04',
  '#3f42ba',
  '#a62929',
  '#9d6b04',
  '#7f890d',
  '#9d6b04',
  '#3f42ba',
  '#a62929',
  '#9d6b04',
  '#7f890d',
  '#9d6b04',
  '#3f42ba',
  '#a62929',
  '#9d6b04',
  '#7f890d',
  '#9d6b04',
  '#3f42ba',
  '#a62929',
  '#9d6b04',
  '#7f890d',
  '#9d6b04',
  '#3f42ba',
  '#a62929',
  '#9d6b04',
  '#7f890d',
  '#9d6b04',
  '#3f42ba',
  '#a62929',
  '#9d6b04',
  '#7f890d',
  '#9d6b04',
  '#3f42ba',
  '#a62929',
  '#9d6b04',
  '#7f890d',
  '#9d6b04',
  '#3f42ba',
  '#a62929',
  '#9d6b04',
  '#7f890d',
  '#9d6b04',
  '#3f42ba',
];

export default {
  colors,
  darkColors,
};
