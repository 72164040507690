import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import EaseIn from '../../Components/EaseIn';
import Title from '../../Components/Text/Title';
import HeaderText from '../../Components/Text/PrimaryText';
import Dashboard from '../../Containers/Dashboard';
import useWindowDimensions from '../../Utilities/WindowDetection';

export default function Results() {
  const {
    state: { input, results },
  } = useLocation();
  const { isMobile } = useWindowDimensions();
  const navigate = useNavigate();
  return (
    <Dashboard>
      <div
        style={{
          padding: 16,
          overflow: 'hidden',
        }}
      >
        {results.length === 0 ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              minHeight: '80vh',
            }}
          >
            <FontAwesomeIcon
              icon={faMagnifyingGlass}
              color="black"
              style={{
                backgroundColor: '#1a1b2a24',
                borderRadius: 4,
                fontSize: 60,
                padding: 26,
                marginBottom: 32,
              }}
            />
            <HeaderText>No Results Found for `{input}`</HeaderText>
          </div>
        ) : (
          <div>
            <Title>Results</Title>
            <div
              style={{
                fontSize: 16,
                display: 'flex',
                // justifyContent: isMobile ? 'flex-start' : 'flex-end',
                color: 'grey',
                paddingBottom: isMobile ? 12 : 8,
              }}
            >
              {results.length} Results Found
            </div>
            <EaseIn>
              <div
                style={{
                  display: 'flex',
                  flexDirection: isMobile ? 'column' : 'row',
                }}
              >
                {results.map((result: any) => (
                  <div
                    onClick={() =>
                      navigate('/business', {
                        state: { business: result },
                      })
                    }
                    style={{
                      borderRadius: 8,
                      marginBottom: isMobile ? 20 : 0,
                      cursor: 'pointer',
                      position: 'relative',
                      marginRight: isMobile ? 0 : 16,
                      width: isMobile ? '100%' : 300,
                      height: 300,
                      backgroundImage: `url(${result.image})`,
                      backgroundSize: `cover`,
                      backgroundPosition: `center`,
                      backgroundRepeat: 'no-repeat',
                      border: `1px solid green`,
                    }}
                  >
                    <div
                      style={{
                        width: '100%',
                        padding: 8,
                        backgroundColor: '#000000a8',
                        position: 'absolute',
                        bottom: 50,
                        borderBottom: `2px solid #54B435`,
                        borderTop: `2px solid #54B435`,
                      }}
                    >
                      <div
                        style={{
                          fontSize: 24,
                          color: 'white',
                        }}
                      >
                        {result.name}
                      </div>
                      <div
                        style={{
                          color: 'white',
                          fontSize: 14,
                        }}
                      >
                        {`${result.profile.substring(0, 56)}...`}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </EaseIn>
          </div>
        )}
      </div>
    </Dashboard>
  );
}
