import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import jwt from 'jwt-decode';
import Dashboard from '../../Containers/Dashboard';
import VideoPlayer from '../../Components/VideoPlayer';
import ProfileDetails from './ProfileDetails';
import {
  getUsersBusiness,
  GetBusinessReels,
  favouritiseBusiness,
  unfavouritiseBusiness,
  getSavedBusinessIds,
} from '../../Api/Calls';
import OtherReels from './OtherReels';
import DeviceQuery from '../../Utilities/DeviceQuery';
import MutatingDots from '../../Components/MutatingDots';

const Root = styled.div`
  overflow: hidden;
  padding: 16px;
  min-height: 100%;
  width: 100%;
`;

const Loader = styled.div`
  display: flex;
  width: 90vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  @media (${DeviceQuery.tablet}) {
    zoom: 1;
    width: 100%;
    overflow: hidden;
    position: fixed;
    height: 90vh;
  }
`;

const ContentRoot = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (${DeviceQuery.mobile}) {
    flex-direction: column;
  }
`;

const Container = styled.div`
  height: 200px;
  width: calc(80% - 1.8rem);
  display: flex;
  flex-direction: column;
  @media (${DeviceQuery.tablet}) {
    width: calc(70% - 1.8rem);
  }
  @media (${DeviceQuery.mobile}) {
    height: 100%;
    width: 100%;
  }
`;

const LeftPane = styled.div`
  position: relative;
  top: -50px;
  @media (${DeviceQuery.mobile}) {
    position: unset;
  }
`;

const RightPane = styled.div`
  padding-top: 20px;
  overflow: hidden;
  height: 100%;
  width: 20%;
  @media (${DeviceQuery.tablet}) {
    width: 30%;
  }
  @media (${DeviceQuery.mobile}) {
    position: relative;
    width: 100%;
  }
`;
export default function Reels() {
  const [loading, setLoading] = React.useState(true);
  const [currentSelection, setCurrentSelection] = React.useState(0);
  const [reels, setReels] = React.useState([]);
  const [favourited, setFavourited] = React.useState(false);
  const [savedBusinesses, setSavedBusinesses] = React.useState([]);
  const token = localStorage.getItem('token');
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { user } = jwt(token);

  const navigate = useNavigate();

  const getEventsForUser = async () => {
    setLoading(true);
    const { rows } = await GetBusinessReels();
    setReels(rows);
    const { rows: favourites } = await getSavedBusinessIds();
    const savedList = favourites.map((fav: any) => fav.business_user_id);
    setSavedBusinesses(savedList);
    setFavourited(savedList.includes(rows[currentSelection].userId));
    setLoading(false);
  };

  React.useEffect(() => {
    getEventsForUser();
  }, []);

  const GetMoreReels = async () => {
    const { rows } = await GetBusinessReels();
    setReels((old) => [...old, ...rows]);
  };

  const nextReel = async () => {
    setLoading(true);
    if (currentSelection === reels.length - 1) {
      await GetMoreReels();
    }
    setCurrentSelection(currentSelection + 1);
    setFavourited(savedBusinesses.includes(reels[currentSelection].userId));

    setTimeout(() => {
      setLoading(false);
    }, 750);
  };

  const prevReel = () => {
    setLoading(true);
    setCurrentSelection(currentSelection - 1);
    setTimeout(() => {
      setLoading(false);
    }, 750);
  };

  const onSelect = (selectedReel: any) => {
    const getFromList = reels.findIndex(
      (reel) => reel.url === selectedReel.url,
    );
    setLoading(true);
    setCurrentSelection(getFromList);
    setFavourited(savedBusinesses.includes(reels[getFromList].userId));
    setTimeout(() => {
      setLoading(false);
    }, 750);
  };

  const filteredReels =
    reels.length > 0 &&
    reels.filter((reel) => reels[currentSelection].url !== reel.url);

  const navigateToProfile = async (id: number) => {
    const { rows } = await getUsersBusiness(id);
    navigate('/business', {
      state: { business: rows[0] },
    });
  };

  const favouritise = async (businessId: number) => {
    setFavourited(true);
    const { rows } = await favouritiseBusiness(user.id, businessId);
    const businessList = rows.map((row: any) => row.business_user_id);
    setSavedBusinesses(businessList);
  };

  const unfavouritise = async (businessId: number) => {
    setFavourited(false);
    const { rows } = await unfavouritiseBusiness(user.id, businessId);
    const businessList = rows.map((row: any) => row.business_user_id);
    setSavedBusinesses(businessList);
  };
  return (
    <Dashboard>
      <Root>
        {loading ? (
          <Loader>
            <MutatingDots />
          </Loader>
        ) : (
          <ContentRoot>
            <Container>
              <LeftPane>
                <VideoPlayer
                  url={reels[currentSelection].url}
                  next={nextReel}
                  prev={prevReel}
                />
                <div
                  style={{
                    top: -100,
                  }}
                >
                  <ProfileDetails
                    next={nextReel}
                    favourited={favourited}
                    setFavourite={async () =>
                      favourited
                        ? unfavouritise(reels[currentSelection].userId)
                        : favouritise(reels[currentSelection].userId)
                    }
                    onClickProfile={() =>
                      navigateToProfile(reels[currentSelection].userId)
                    }
                    image={reels[currentSelection].image}
                    title={reels[currentSelection].name}
                    desc={reels[currentSelection].profile}
                  />
                </div>
              </LeftPane>
            </Container>
            <RightPane>
              <OtherReels contents={filteredReels} onSelect={onSelect} />
            </RightPane>
          </ContentRoot>
        )}
      </Root>
    </Dashboard>
  );
}
