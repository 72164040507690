import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';

type TitleProps = {
  children: string | ReactNode;
};

export default function Title({ children }: TitleProps) {
  const { darkMode } = useSelector((state: any) => state.Config);

  return (
    <div
      style={{
        fontSize: 18,
        fontWeight: 500,
        color: darkMode ? 'white' : 'black',
      }}
    >
      {children}
    </div>
  );
}
