import React from 'react';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';

type Props = {
  minDate: any;
  maxDate: any;
  setDate: (date: string) => void;
  date: string;
};
export default function AddDate({ setDate, date, minDate, maxDate }: Props) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MobileDatePicker
        minDate={minDate}
        maxDate={maxDate}
        label="Date"
        inputFormat="DD/MM/YYYY"
        value={date}
        onChange={setDate}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
  );
}
