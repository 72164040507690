import Dashboard from './Dashboard';
import User from './User';
import Config from './Config';
import ViewedProfiles from './ViewedProfiles';

export interface RootModel {
  Dashboard: typeof Dashboard;
  User: typeof User;
  Config: typeof Config;
  ViewedProfiles: typeof ViewedProfiles;
}

export const models: RootModel = { Dashboard, User, Config, ViewedProfiles };
