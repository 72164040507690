// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import Button from '@mui/material/Button';
import Sample from './Fields/Sample.json';
import ButtonGroup from './Fields/ButtonGroup';
import Type from './Fields/Types';
import Date from './Fields/Date';
import ContactForm from './Fields/ContactForm';
import MultilineField from './Fields/MultiLineField';

export default function Enquire() {
  const [inputValues, setInputValues] = React.useState({});

  const handleChange = ({ id, ...props }: any) => {
    setInputValues({
      ...inputValues,
      [id]: {
        ...props,
      },
    });
  };
  const renderType = (field: any) => {
    const filterType = Object?.entries(inputValues)?.filter(
      ([k, v]) => Number(k) === Number(field.id),
    )[0];
    const selected =
      filterType !== undefined && filterType.length > 0
        ? (filterType[1]?.value as string)
        : null;
    switch (field.type) {
      case Type.BUTTON_GROUP:
        return (
          <ButtonGroup
            field={field}
            selected={selected}
            onClick={handleChange}
          />
        );
      case Type.DATE_TIME:
        return (
          <Date field={field} selected={selected} onClick={handleChange} />
        );
      case Type.CONTACT_FORM:
        return <ContactForm />;

      case Type.MULTILINE_FIELD:
        return <MultilineField />;
      default:
        return null;
    }
  };

  return (
    <div
      style={{
        padding: `32px 18px 32px 18px`,
      }}
    >
      <div
        style={{
          fontSize: 32,
          color: 'white',
          fontWeight: 'bold',
        }}
      >
        Enquiry Form
      </div>
      {Sample.map((field) => (
        <div
          style={{
            color: 'white',
            fontSize: 16,
            marginBottom: 16,
            fontWeight: '200',
          }}
        >
          {field.title}
          {renderType(field)}
        </div>
      ))}
      <Button variant="contained">Submit</Button>
    </div>
  );
}
