import {
  faUser,
  faCar,
  faFilm,
  faChurch,
  faBuilding,
  faRing,
  faMusic,
  faMicrophone,
  faUtensils,
  faStar,
  faHeart,
  faCalendarDays,
  faCartShopping,
  faPen,
  faBook,
  faVideo,
  faEye,
  faCity,
  faHandshake,
  faStreetView,
  faHouse,
  faThumbtack,
  faSquare,
  faCircle,
  faCircleNodes,
  faShapes,
  faUsersRectangle,
  faList,
  faHandsPraying,
  faGift,
  faQuestion,
  faUserTie,
  faVenusMars,
  faLocationDot,
  faComment,
  faComments,
  faMinus,
  faMoon,
  faMugSaucer,
} from '@fortawesome/free-solid-svg-icons';

const Icons = [
  {
    name: 'faHouse',
    icon: faHouse,
  },
  {
    name: 'faUser',
    icon: faUser,
  },
  {
    name: 'faCar',
    icon: faCar,
  },
  {
    name: 'faFilm',
    icon: faFilm,
  },
  {
    name: 'faChurch',
    icon: faChurch,
  },
  {
    name: 'faBuilding',
    icon: faBuilding,
  },
  {
    name: 'faRing',
    icon: faRing,
  },
  {
    name: 'faMusic',
    icon: faMusic,
  },
  {
    name: 'faMicrophone',
    icon: faMicrophone,
  },
  {
    name: 'faUtensils',
    icon: faUtensils,
  },
  {
    name: 'faStar',
    icon: faStar,
  },
  {
    name: 'faHeart',
    icon: faHeart,
  },
  {
    name: 'faCalendarDays',
    icon: faCalendarDays,
  },
  {
    name: 'faCartShopping',
    icon: faCartShopping,
  },
  {
    name: 'faPen',
    icon: faPen,
  },
  {
    name: 'faBook',
    icon: faBook,
  },
  {
    name: 'faVideo',
    icon: faVideo,
  },
  {
    name: 'faEye',
    icon: faEye,
  },
  {
    name: 'faCity',
    icon: faCity,
  },
  {
    name: 'faHandshake',
    icon: faHandshake,
  },
  {
    name: 'faStreetView',
    icon: faStreetView,
  },
  {
    name: 'faStreetView',
    icon: faStreetView,
  },
  {
    name: 'faThumbtack',
    icon: faThumbtack,
  },
  {
    name: 'faSquare',
    icon: faSquare,
  },
  {
    name: 'faCircle',
    icon: faCircle,
  },
  {
    name: 'faCircleNodes',
    icon: faCircleNodes,
  },
  {
    name: 'faShapes',
    icon: faShapes,
  },
  {
    name: 'faUsersRectangle',
    icon: faUsersRectangle,
  },
  {
    name: 'faList',
    icon: faList,
  },
  {
    name: 'faHandsPraying',
    icon: faHandsPraying,
  },
  {
    name: 'faGift',
    icon: faGift,
  },
  {
    name: 'faQuestion',
    icon: faQuestion,
  },
  {
    name: 'faUserTie',
    icon: faUserTie,
  },
  {
    name: 'faVenusMars',
    icon: faVenusMars,
  },
  {
    name: 'faLocationDot',
    icon: faLocationDot,
  },
  {
    name: 'faComment',
    icon: faComment,
  },
  {
    name: 'faComments',
    icon: faComments,
  },
  {
    name: 'faMinus',
    icon: faMinus,
  },
  {
    name: 'faMoon',
    icon: faMoon,
  },
  {
    name: 'faMugSaucer',
    icon: faMugSaucer,
  },
];

export default Icons;
