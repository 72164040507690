/* eslint-disable import/prefer-default-export */

import jwtDecode from 'jwt-decode';

export function IsTokenValid() {
  const token = localStorage.getItem('token');
  try {
    if (token === null || token === undefined) {
      localStorage.clear();
      return false;
    }
    const decode = jwtDecode(token);
    const { exp } = decode;
    const expiry = new Date(exp * 1000);
    const now = new Date();
    return expiry.getTime() > now.getTime();
  } catch (error) {
    console.error(error);
    localStorage.clear();
    return false;
  }
}
