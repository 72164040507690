import React from 'react';
import { useDispatch } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';
import { BusinessSearch } from '../../Api/Calls';
import SearchBar from './SearchBar';
import useWindowDimensions from '../../Utilities/WindowDetection';

/**
 * videos downloaded from
 * https://www.istockphoto.com/video/gps-navigation-localization-seamless-loop-gm843867804-138225077?phrase=maps
 */
export default function Search() {
  const navigate = useNavigate();
  const [error, setError] = React.useState(false);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const onSearch = async (input) => {
    if (input.length === 0) {
      return setError(true);
    }
    const { rows } = await BusinessSearch(input);
    if (rows.length === 0) {
      setError(true);
    } else {
      navigate('/search-results', {
        state: { input, results: rows },
      });
    }
  };

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1,
      }}
    >
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={error}
        autoHideDuration={3000}
        onClose={() => setError(false)}
      >
        <Alert severity="error">No results found</Alert>
      </Snackbar>

      <SearchBar onSearch={onSearch} />
    </div>
  );
}
