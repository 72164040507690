import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import DeviceQuery from '../../Utilities/DeviceQuery';

const desktopAnim = keyframes`
 0% {  width: 5rem; }
 100% { width: 30rem; }
`;

const mobileAnim = keyframes`
 0% {  width: 5rem; }
 100% { width: 14rem; }
`;

const Container = styled.div.attrs((props: { open: boolean }) => props)`
  z-index: 9999;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  height: 3rem;
  width: ${(props) => (props.open ? `30rem` : `0rem`)};
  background: white;
  border: 1px solid #c9c9ce;
  border-radius: 10px;
  padding: 1rem;
  animation-name: ${desktopAnim};
  animation-duration: 1.5s;
  @media (max-width: 576px) {
    animation-name: ${mobileAnim};
    animation-duration: 1.5s;
    width: 14rem;
  }
`;

const InputField = styled.input`
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  color: grey;
  font-family: 'Lexend';
  font-size: 12px;
`;
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default function SearchBar({ onSearch }) {
  const { t } = useTranslation();
  const placeholderText = '';
  const [input, setInput] = useState<string>(placeholderText);
  const [focused, setFocused] = useState<boolean>(false);
  const [open, setOpen] = useState(false);

  const onChange = (value: string): void => {
    setInput(value);
  };

  const onFocus = (): void => {
    setFocused(true);
    if (input === placeholderText) {
      setInput('');
    }
  };

  const onBlur = (): void => {
    setOpen(false);
    setFocused(false);
    if (!input) {
      setInput(placeholderText);
    }
  };

  const onSubmit = (): void => {
    onSearch(input);
  };

  if (!open) {
    return (
      <div
        style={{
          display: 'flex',
          height: `3rem`,
          alignItems: 'center',
          marginBottom: '16px',
        }}
      >
        <FontAwesomeIcon
          style={{
            fontSize: 15,
            backgroundColor: 'white',
            border: `1px solid #c9c9ce`,
            borderRadius: `10px`,
            padding: `1rem`,
            // paddingRight: 16,
            color: 'black',
            cursor: 'pointer',
          }}
          onClick={() => setOpen(true)}
          icon={faMagnifyingGlass}
        />
      </div>
    );
  }

  return (
    <Container open={open}>
      <InputField
        placeholder={open ? 'What are you looking for?' : ''}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onSubmit();
          }
        }}
        onChange={({ target }) => {
          onChange(target.value);
        }}
        onFocus={() => {
          onFocus();
        }}
        onBlur={() => {
          onBlur();
        }}
        value={input}
      />
      <FontAwesomeIcon
        style={{
          fontSize: 15,
          cursor: 'pointer',
        }}
        onClick={() => onSubmit()}
        icon={faMagnifyingGlass}
      />
    </Container>
  );
}
