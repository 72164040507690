import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import DeviceQuery from '../../Utilities/DeviceQuery';

type TitleProps = {
  children: string | ReactNode;
};

const StyledTitle = styled.div.attrs((props: { darkMode: boolean }) => props)`
  color: ${(props) => (props.darkMode ? 'white' : 'black')};
  font-size: 36px;
  font-weight: 300;
  margin-bottom: 10px;
  @media (${DeviceQuery.tablet}) {
    font-size: 30px;
  }
`;

export default function Title({ children }: TitleProps) {
  const { darkMode } = useSelector((state: any) => state.Config);
  return <StyledTitle darkMode={darkMode}>{children}</StyledTitle>;
}
