import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import moment from 'moment';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Oval } from 'react-loader-spinner';
import Text from '../../Components/Text/BaseText';
import DeviceQuery from '../../Utilities/DeviceQuery';
import MutatingDots from '../../Components/MutatingDots';

type Props = {
  onClick: () => void;
  deletingId: number;
  onDelete: () => void;
  job: any;
  color: string;
  onEdit: (job: any) => void;
};

const Root = styled.div<{ color: string }>`
  margin: 0px 0px 0px 16px;
  background-color: ${(props) => props.color};
  position: relative;
  cursor: pointer;
  padding: 16px;
  width: 300px;
  height: 180px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: space-between;
  @media (${DeviceQuery.tablet}) {
    width: calc(50vw - 2.2rem);
  }
  @media (${DeviceQuery.mobile}) {
    width: calc(100vw - 32px);
  }
`;

const Footer = styled.div`
  filter: contrast(70%);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  margin: 0px 0px 16px 16px;
  display: flex;
  justify-content: flex-end;
  @media (${DeviceQuery.tablet}) {
    width: calc(50vw - 2.2rem);
  }
  @media (${DeviceQuery.mobile}) {
    width: calc(100vw - 32px);
  }
`;
export default function JobCard({
  onEdit,
  deletingId,
  onDelete,
  job,
  color,
  onClick,
}: Props) {
  const { darkMode } = useSelector((state: any) => state.Config);
  const [hover, setHover] = React.useState(null);
  const [hoverDeleteId, setHoverDeleteId] = React.useState(null);
  const [hoverEditId, setHoverEditId] = React.useState(null);
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Root
        onClick={onClick}
        onMouseEnter={() => setHover(job.id)}
        onMouseLeave={() => setHover(null)}
        color={color}
        style={
          hover !== null
            ? {
                filter:
                  hover !== null && hover === job.id
                    ? `brightness(90%)`
                    : `brightness(100%)`,
              }
            : {
                filter:
                  deletingId !== null && deletingId === job.id
                    ? `brightness(50%)`
                    : `brightness(100%)`,
              }
        }
      >
        {deletingId !== null && deletingId === job.id && (
          <div
            style={{
              position: 'absolute',
              marginLeft: 'auto',
              marginRight: 'auto',
              top: 80,
              bottom: 0,
              right: 20,
              textAlign: 'center',
              zoom: 0.75,
            }}
          >
            <MutatingDots />
          </div>
        )}

        <Text
          style={{
            fontSize: 18,
            fontWeight: 'bold',
          }}
        >
          {job.title}
        </Text>
        <div>
          <Text
            style={{
              paddingTop: 8,
            }}
          >
            Responses
          </Text>
          <Text
            style={{
              fontSize: 20,
              fontWeight: 'bold',
              paddingBottom: 32,
            }}
          >
            0
          </Text>
        </div>
        <div
          style={{
            position: 'absolute',
            bottom: 10,
            left: 10,
            fontSize: 10,
            color: darkMode ? 'white' : 'grey',
          }}
        >
          Posted on: {moment(job.date).format('MMM Do YY')}
        </div>
      </Root>
      <Footer
        style={
          hover !== null
            ? {
                backgroundColor: color,
                filter:
                  hover !== null && hover === job.id
                    ? `brightness(80%)`
                    : `brightness(90%)`,
              }
            : {
                backgroundColor: color,
                filter:
                  deletingId !== null && deletingId === job.id
                    ? `brightness(80%)`
                    : `brightness(90%)`,
              }
        }
      >
        <FontAwesomeIcon
          onMouseEnter={() => setHoverEditId(job.id)}
          onMouseLeave={() => setHoverEditId(null)}
          onClick={() => onEdit(job)}
          icon={faPenToSquare}
          color={hoverEditId === job.id ? 'blue' : 'black'}
          style={{
            opacity: deletingId !== null && deletingId === job.id ? 0 : 1,
            cursor: 'pointer',
            fontSize: 16,
            padding: 10,
            marginRight: 8,
          }}
        />
        <FontAwesomeIcon
          onMouseEnter={() => setHoverDeleteId(job.id)}
          onMouseLeave={() => setHoverDeleteId(null)}
          onClick={onDelete}
          icon={faTrashCan}
          color={hoverDeleteId === job.id ? 'blue' : 'black'}
          style={{
            opacity: deletingId !== null && deletingId === job.id ? 0 : 1,
            cursor: 'pointer',
            fontSize: 16,
            padding: 10,
            marginRight: 8,
          }}
        />
      </Footer>
    </div>
  );
}
