import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import palette from '../../../Utilities/Palette';

const Root = styled.select`
  background: ${palette.OFF_WHITE};
  border: 1px solid ${palette.LIGHT_GREY};
  box-shadow: inset 0px 3px 6px #00000029;
  border-radius: 4px;
  min-height: 50px;
  padding-left: 12px;
  font-family: 'Lexend';
  font-size: 18px;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1em;
`;

const Header = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
  font-family: 'Lexend';
`;

type Props = {
  country: string;
  setCountry: (country: string) => void;
};
export default function CountrySelect({ country, setCountry }: Props) {
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    fetch(
      'https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code',
    )
      .then((response) => response.json())
      .then((data) => {
        const parsedData = data.countries
          .map((c: any) => c.label.slice(5))
          .sort();
        const filtered = parsedData.filter((d: string) => d !== 'Israel');
        setCountries(filtered);
      });
  }, []);

  return (
    <>
      <Header>Country *</Header>
      <Root value={country} onChange={(e) => setCountry(e.target.value)}>
        {countries.map((c) => (
          <option value={c}>{c}</option>
        ))}
      </Root>
    </>
  );
}
