import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser,
  faForwardFast,
  faHeart,
} from '@fortawesome/free-solid-svg-icons';
import { faHeart as faHeartEmpty } from '@fortawesome/free-regular-svg-icons';

import Text from '../../Components/Text/BaseText';
import DeviceQuery from '../../Utilities/DeviceQuery';

type Props = {
  image: string;
  title: string;
  desc: string;
  next: () => void;
  onClickProfile: () => void;
  favourited: boolean;
  setFavourite: () => void;
};

const Root = styled.div`
  border-radius: 2px;
  background-color: #ffffff27;
  z-index: 9999;
  margin-top: 20px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  @media (${DeviceQuery.tablet}) {
    flex-direction: column;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
  @media (${DeviceQuery.mobile}) {
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  @media (${DeviceQuery.tablet}) {
    flex-direction: column;
  }
`;

const Image = styled.div.attrs((props: { image: string }) => props)`
  background-image: ${(props) => `url(${props.image})`};
  background-repeat: no-repeat, no-repeat;
  background-position: center;
  background-size: cover;
  height: 75px;
  width: 75px;
  border-radius: 8px;
  margin-right: 8px;
  @media (${DeviceQuery.tablet}) {
    margin-left: 12px;
    width: 150px;
    height: 150px;
    flex-direction: column;
  }
  @media (${DeviceQuery.mobile}) {
    height: 200px;
    width: 200px;
    background-size: contain;
  }
`;

const Top = styled.div`
  position: relative;
  top: -50px;
  @media (${DeviceQuery.mobile}) {
    top: 0px;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  @media (${DeviceQuery.mobile}) {
    flex-direction: column-reverse;
  }
`;

const Position = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  text-align: center;
  @media (${DeviceQuery.tablet}) {
    flex-direction: column;
  }
`;

export default function ProfileDetails({
  favourited,
  setFavourite,
  onClickProfile,
  image,
  title,
  desc,
  next,
}: Props) {
  return (
    <Top>
      <Root>
        <Container>
          <Content>
            <Position>
              <Image image={image} />
              <div>
                <Text
                  style={{
                    color: 'white',
                    margin: 8,
                    fontSize: 26,
                    fontWeight: 'bold',
                  }}
                >
                  {title}
                </Text>
              </div>
            </Position>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: 16,
              }}
            >
              <FontAwesomeIcon
                onClick={onClickProfile}
                style={{
                  marginLeft: 8,
                  marginRight: 8,
                  cursor: 'pointer',
                  backgroundColor: '#a4b6dd',
                  padding: 16,
                  borderRadius: 4,
                  fontSize: 16,
                }}
                icon={faUser}
                color="black"
              />
              <FontAwesomeIcon
                onClick={setFavourite}
                style={{
                  marginRight: 8,
                  cursor: 'pointer',
                  backgroundColor: '#c094cc',
                  padding: 16,
                  borderRadius: 4,
                  fontSize: 16,
                }}
                icon={favourited ? faHeart : faHeartEmpty}
                color={favourited ? 'darkred' : 'black'}
              />
              <FontAwesomeIcon
                onClick={next}
                style={{
                  cursor: 'pointer',
                  backgroundColor: '#c37892',
                  padding: 16,
                  borderRadius: 4,
                  fontSize: 16,
                  marginRight: 8,
                }}
                icon={faForwardFast}
                color="black"
              />
            </div>
          </Content>
        </Container>
      </Root>
      <Text
        style={{
          fontSize: 16,
          padding: 4,
          marginTop: 8,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          lineClamp: 4,
          WebkitLineClamp: 4,
          WebkitBoxOrient: 'vertical',
        }}
      >
        {desc}
      </Text>
    </Top>
  );
}
