import React from 'react';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';

type Props = {
  field: any;
  onClick: (val: any) => void;
  selected: string;
};

export default function Date({ selected, field, onClick }: Props) {
  return (
    <div
      style={{
        display: 'flex',
        marginTop: 16,
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MobileDatePicker
          disablePast
          inputFormat="DD/MM/YYYY"
          value={selected}
          onChange={(value) =>
            onClick({
              id: field.id,
              question: field.title,
              value,
            })
          }
          renderInput={(params) => (
            <TextField
              placeholder="Select Date"
              {...params}
              sx={{
                input: {
                  color: 'white',
                  background: 'black',
                  borderRadius: 2,
                  border: `2px solid #46709e`,
                },
              }}
            />
          )}
        />
      </LocalizationProvider>
    </div>
  );
}
