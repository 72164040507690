import React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useSelector, useDispatch } from 'react-redux';
import Dashboard from '../../Containers/Dashboard';

export default function Settings() {
  const { darkMode } = useSelector((state: any) => state.Config);
  const dispatch = useDispatch();
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    value: boolean,
  ) => {
    dispatch.Config.setMode(value);
  };
  return (
    <Dashboard>
      <div
        style={{
          minHeight: ' 100vh',
          padding: 23,
        }}
      >
        <div
          style={{
            color: darkMode ? 'white' : 'black',
            fontSize: 32,
          }}
        >
          Enable Dark Mode
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              fontSize: 16,
              fontWeight: 300,
              color: darkMode ? 'white' : 'black',
              width: '70%',
            }}
          >
            Dark mode's aim is to reduce the glaring white light that may be
            distracting in the evenings. It may even be easier on your eyes than
            a traditional screen setting
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              width: '30%',
            }}
          >
            <ToggleButtonGroup
              color="primary"
              value={darkMode}
              exclusive
              onChange={handleChange}
              aria-label="Platform"
            >
              <ToggleButton
                style={
                  darkMode
                    ? { backgroundColor: '#00009f' }
                    : { backgroundColor: 'white' }
                }
                value
              >
                <div>On</div>
              </ToggleButton>
              <ToggleButton
                style={
                  !darkMode
                    ? { backgroundColor: '#ee4103' }
                    : { backgroundColor: 'white' }
                }
                value={false}
              >
                <div>OFF</div>
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>
      </div>
    </Dashboard>
  );
}
