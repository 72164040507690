import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import DeviceQuery from '../../Utilities/DeviceQuery';

const Types = {
  YOUTUBE: 'youtube',
  SPOTIFY: 'spotify',
  SOUNDCLOUD: 'soundcloud',
};

const Content = styled.div`
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 2.5rem);
  @media (${DeviceQuery.tablet}) {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
  }
  @media (${DeviceQuery.mobile}) {
    overflow: unset;
    height: unset;
  }
`;

const Image = styled.div.attrs((props: { image: string }) => props)`
  position: relative;
  margin: 8px;
  min-height: 250px;
  border: 1px solid #000000b8;
  width: 94%;
  border-radius: 8px;
  background-image: ${(props) => `url(${props.image})`};
  background-repeat: no-repeat, no-repeat;
  background-size: cover;
  background-position: center;
  @media (${DeviceQuery.tablet}) {
    min-height: unset;
    height: 200px;
    width: 100%;
  }
`;

const Root = styled.div`
  position: relative;
  overflow: hidden;
  top: -20px;
  @media (${DeviceQuery.mobile}) {
    position: unset;
    top: 0px;
  }
`;

export default function OtherReels({ contents, onSelect }: any) {
  const youTubeIdFromLink = (url: string) =>
    url.match(
      /(?:https?:\/\/)?(?:www\.|m\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\/?\?v=|\/embed\/|\/)([^\s&\?\/\#]+)/,
    )[1];

  return (
    <Root>
      <Content>
        {contents.map((reel: any) => {
          let image = '';
          let content = '';
          switch (reel.type) {
            case Types.YOUTUBE:
              content = 'Video';
              image = `http://img.youtube.com/vi/${youTubeIdFromLink(
                reel.url,
              )}/maxresdefault.jpg`;
              break;
            case Types.SOUNDCLOUD:
              content = 'Music';
              image =
                'https://techcrunch.com/wp-content/uploads/2016/01/soundcloud-reverse.png';
              break;
            default:
              content = 'Music';
              image =
                'https://techcrunch.com/wp-content/uploads/2016/01/soundcloud-reverse.png';
              break;
          }
          return (
            <Image image={image}>
              <div
                style={{
                  paddingTop: 6,
                  paddingBottom: 6,
                  paddingLeft: 12,
                  paddingRight: 12,
                  backgroundColor: '#000000b8',
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                  fontSize: 14,
                  textAlign: 'center',
                  color: '#ffffffc3',
                }}
              >
                {content}
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  boxShadow: `rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px`,
                }}
              >
                <FontAwesomeIcon
                  onClick={() => onSelect(reel)}
                  style={{
                    cursor: 'pointer',
                    backgroundColor: 'grey',
                    padding: 16,
                    borderRadius: 4,
                    fontSize: 16,
                  }}
                  icon={faPlay}
                  color="white"
                />
              </div>
            </Image>
          );
        })}
      </Content>
    </Root>
  );
}
