import React from 'react';
import Text from '../../../Components/Text/BaseText';
import EaseIn from '../../../Components/EaseIn';
import ImageGallery from '../ImageGallery';
import { getGalleryImages } from '../../../Api/Calls';

type Props = {
  id: number;
};
export default function Gallery({ id }: Props) {
  const [loading, setLoading] = React.useState(true);
  const [images, setImages] = React.useState([]);
  const getData = async () => {
    setLoading(true);
    const { rows } = await getGalleryImages(id);
    const src = rows.map((row: any) => ({
      src: row.url,
      original: row.url,
      width: '100%',
      height: 'auto',
    }));
    setImages(src);
    setLoading(false);
  };
  React.useEffect(() => {
    getData();
  }, [id]);

  if (loading) {
    return null;
  }
  return (
    <EaseIn>
      <Text
        style={{
          fontSize: 32,
          paddingBottom: 8,
          fontWeight: 120,
        }}
      >
        GALLERY
      </Text>
      <div>
        <ImageGallery images={images} />
      </div>
    </EaseIn>
  );
}
