import React from 'react';
import { useTranslation } from 'react-i18next';
import TextTransition, { presets } from 'react-text-transition';

const TEXTS = [
  'PERFECT DAY',
  'EVENTS',
  'WEDDINGS',
  'CONFERENCES',
  'PARTIES',
  'ALL',
];

const TITLE = ['PLAN', '📅', '⛪', '🏤', '🎉', 'P'];
const COLORS = ['black', '#e46434', 'red', '#a88a53', '#9c27b0', '#53917e'];

type Props = {
  interval?: number;
};
export default function LogoAnimation({ interval }: Props) {
  const [index, setIndex] = React.useState(0);

  React.useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      interval,
    );
    return () => clearTimeout(intervalId);
  }, []);

  const { t } = useTranslation();
  return (
    <>
      <div
        style={{
          display: 'flex',
          height: 120,
        }}
      >
        <h1
          style={{
            fontSize: 80,
            fontWeight: 'bold',
          }}
        >
          <TextTransition
            translateValue="5%"
            inline
            springConfig={presets.stiff}
          >
            {TITLE[index % TITLE.length]}
          </TextTransition>
        </h1>
        <h1
          style={{
            fontWeight: 'bold',
            color: '#cc2434',
            fontSize: 40,
          }}
        >
          {index % TITLE.length === 0 || index % TITLE.length === 5 ? '4' : ''}
        </h1>
      </div>

      <div
        style={{
          justifyContent: 'flex-start',
          display: 'flex',
        }}
      >
        <h4
          style={{
            fontSize: 22,
            fontWeight: 300,
            paddingRight: 4,
          }}
        >
          PLAN
        </h4>
        <h4
          style={{
            paddingRight: 4,
            fontWeight: 300,
            fontSize: 22,
            color: index % TEXTS.length === 0 ? 'black' : '#2c4c7c',
          }}
        >
          <TextTransition
            translateValue="5%"
            inline
            springConfig={presets.slow}
          >
            {index % TEXTS.length === 0 ? `YOUR` : 'FOR'}
          </TextTransition>
        </h4>
        <h4
          style={{
            fontSize: 22,
            fontWeight: 300,
            color: COLORS[index % TEXTS.length],
          }}
        >
          <TextTransition
            translateValue="5%"
            inline
            springConfig={presets.slow}
          >
            {TEXTS[index % TEXTS.length]}
          </TextTransition>
        </h4>
      </div>
    </>
  );
}

LogoAnimation.defaultProps = {
  interval: 3500,
};
