import React from 'react';
import jwt from 'jwt-decode';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '../../Components/Button';
import { UpdateJob } from '../../Api/Calls';
import MutatingDots from '../../Components/MutatingDots';

type Props = {
  onClose: () => void;
  job: any;
  open: boolean;
  setJobs: (jobs: any) => void;
};
export default function JobDrawer({ job, onClose, setJobs, open }: Props) {
  const [title, setTitle] = React.useState('');
  const [desc, setDesc] = React.useState('');
  const [price, setPrice] = React.useState('');
  const [date, setDate] = React.useState(new Date());
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setTitle(job.title);
    setDesc(job.desc);
    setPrice(job.pricing);
    setDate(new Date(job.expectedDate));
  }, [job]);

  const updateJob = async () => {
    setLoading(true);
    const { rows } = await UpdateJob(
      job.id,
      job.date,
      title,
      desc,
      date,
      price,
    );
    setJobs(rows);
    setLoading(false);
    onClose();
  };

  const style = {
    zoom: 0.9,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 1,
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <div
          style={{
            padding: 16,
            width: 350,
          }}
        >
          <div
            style={{
              fontSize: 26,
              paddingBottom: 32,
            }}
          >
            Edit <b>{job.title}</b>
          </div>
          <div
            style={{
              paddingBottom: 16,
            }}
          >
            <TextField
              disabled={loading}
              defaultValue={job.title}
              fullWidth
              onChange={(e) => setTitle(e.target.value)}
              id="outlined-basic"
              label="Job Title"
              variant="outlined"
            />
            <OutlinedInput
              disabled={loading}
              defaultValue={job.pricing}
              fullWidth
              placeholder="Estimated Price"
              style={{
                marginTop: 16,
              }}
              onChange={(e) => setPrice(e.target.value)}
              margin="none"
              id="outlined-adornment-amount"
              startAdornment={
                <InputAdornment position="start">£</InputAdornment>
              }
            />
          </div>
          <div
            style={{
              paddingBottom: 16,
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                disabled={loading}
                disablePast
                label="Expected Date"
                inputFormat="DD/MM/YYYY"
                value={date}
                onChange={setDate}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>
          <TextField
            disabled={loading}
            defaultValue={job.description}
            style={{
              marginBottom: 8,
            }}
            multiline
            rows={4}
            fullWidth
            onChange={(e) => setDesc(e.target.value)}
            id="outlined-multiline-static"
            label="Description"
            variant="outlined"
          />
          <Button
            name={
              loading ? (
                <div
                  style={{
                    position: 'absolute',
                    zoom: 0.4,
                    left: '25rem',
                    bottom: 79,
                  }}
                >
                  <MutatingDots />
                </div>
              ) : (
                'Update Job'
              )
            }
            onClick={loading ? () => null : () => updateJob()}
          />
        </div>
      </Box>
    </Modal>
  );
}
