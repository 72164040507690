import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import moment from 'moment';
import styled, { keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCommentAlt,
  faMessage,
  faRectangleXmark,
} from '@fortawesome/free-regular-svg-icons';
import useWindowDimensions from '../../Utilities/WindowDetection';

const Tab = styled.div<{
  $selected: number;
  $value: number;
}>`
  cursor: pointer;
  padding: 16px;
  width: 100%;
  background-color: ${(props) =>
    props.$selected === props.$value ? `lightgrey` : `darkgrey`};
  text-align: center;
  color: ${(props) => (props.$selected === props.$value ? `black` : `grey`)};
  font-weight: ${(props) => (props.$selected === props.$value ? `400` : `200`)};
  border-bottom: ${(props) =>
    props.$selected === props.$value
      ? `4px solid purple`
      : `4px solid darkgrey`};
`;
type Props = {
  onClose: () => void;
  open: boolean;
  job: any;
};
export default function ViewJob({ job, onClose, open }: Props) {
  const [activeTab, setActiveTab] = React.useState(0);
  const { date, description, expectedDate, pricing, title } = job;
  const { isMobile } = useWindowDimensions();
  const style = {
    zoom: 0.9,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'black',
    border: '2px solid #000',
    boxShadow: 24,
    borderRadius: 1,
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          ...style,
          height: isMobile ? '100%' : '90vh',
          width: isMobile ? '100%' : '90%',
          overflow: 'scroll',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
          }}
        >
          <Tab $selected={activeTab} $value={0} onClick={() => setActiveTab(0)}>
            <FontAwesomeIcon icon={faCommentAlt} style={{ paddingRight: 8 }} />
            Job Details
          </Tab>
          <Tab $selected={activeTab} $value={1} onClick={() => setActiveTab(1)}>
            <FontAwesomeIcon icon={faMessage} style={{ paddingRight: 8 }} />
            Responses
          </Tab>
          <FontAwesomeIcon
            onClick={onClose}
            icon={faRectangleXmark}
            style={{
              borderTopRightRadius: 8,
              color: 'white',
              cursor: 'pointer',
              paddingLeft: 16,
              paddingRight: 16,
            }}
          />
        </div>
        {activeTab === 0 && (
          <div
            style={{
              padding: 16,
              color: 'white',
            }}
          >
            <h1
              style={{
                fontSize: 28,
                fontWeight: 'bold',
              }}
            >
              {title}
            </h1>
            <div style={{ marginBottom: 32 }}>
              Posted on: {moment(date).format('MMM Do YYYY')}
            </div>
            <div
              style={{
                fontWeight: '200',
                fontSize: 24,
                paddingBottom: 8,
              }}
            >
              Expected on
            </div>
            <div style={{ marginBottom: 32 }}>
              {moment(expectedDate).format('MMM Do YYYY')}
            </div>
            <div
              style={{
                fontWeight: '200',
                fontSize: 24,
                paddingBottom: 8,
              }}
            >
              Estimate Price
            </div>
            <div style={{ marginBottom: 32 }}>
              £{Number(pricing).toFixed(2)}
            </div>
            <div
              style={{
                fontWeight: '200',
                fontSize: 24,
                paddingBottom: 8,
              }}
            >
              Description
            </div>
            <p>
              {description === null || description?.length === 0
                ? 'No description provided'
                : description}
            </p>
          </div>
        )}
        {activeTab === 1 && (
          <div
            style={{
              fontSize: 20,
              padding: 16,
              color: 'white',
            }}
          >
            0 Responses
          </div>
        )}
      </Box>
    </Modal>
  );
}
