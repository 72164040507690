import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faRectangleXmark,
  faPenToSquare,
  faTrashCan,
} from '@fortawesome/free-regular-svg-icons';
import { faSquarePlus } from '@fortawesome/free-solid-svg-icons';

import TextField from '@mui/material/TextField';
import moment from 'moment';
import { Oval } from 'react-loader-spinner';
import AddDate from './TableRow/AddDate';
import AddTime from './TableRow/AddTime';
import {
  updateTaskForEvent,
  addTaskToEvent,
  deleteTaskForEvent,
  getTasksForEvent,
} from '../../Api/Calls';

type Props = {
  eventId: number;
  dateFrom: any;
  dateTo: any;
};
export default function TaskList({ eventId, dateFrom, dateTo }: Props) {
  const [add, setAdd] = React.useState(false);
  const [edit, setEdit] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [tasks, setTasks] = React.useState([]);

  const [name, setName] = React.useState('');
  const [date, setDate] = React.useState(dateFrom);
  const [time, setTime] = React.useState(dateFrom);

  const [updateTaskLoader, setUpdateTaskLoader] = React.useState(false);

  const [deleteTaskID, setDeleteTaskID] = React.useState(null);
  const clearTask = () => {
    setName('');
    setEdit(null);
    setDate(dateFrom);
    setTime(dateFrom);
    setAdd(false);
  };
  const getTasks = async () => {
    const { rows } = await getTasksForEvent(eventId);
    if (rows && rows.length > 0) {
      setTasks(rows);
    }
  };

  const addTask = async () => {
    setUpdateTaskLoader(true);
    const { rows } = await addTaskToEvent(eventId, name, date, time);
    setTasks(rows);
    clearTask();
    setUpdateTaskLoader(false);
  };

  const deleteTask = async (id: number) => {
    setDeleteTaskID(id);
    setUpdateTaskLoader(true);
    const { rows } = await deleteTaskForEvent(id, eventId);
    setTasks(rows === null ? [] : rows);
    setUpdateTaskLoader(false);
    setDeleteTaskID(null);
  };

  const updateTask = async (taskId: number) => {
    setUpdateTaskLoader(true);
    const { rows } = await updateTaskForEvent(
      eventId,
      name,
      date,
      time,
      taskId,
    );
    setTasks(rows);
    clearTask();
    setUpdateTaskLoader(false);
  };

  React.useEffect(() => {
    setLoading(true);
    getTasks();
    setLoading(false);
  }, []);

  if (loading) {
    return null;
  }

  const actions = (task: any) => {
    if (deleteTaskID === task.id && updateTaskLoader) {
      return <Oval width="100" color="black" />;
    }
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <FontAwesomeIcon
          onClick={() => {
            setAdd(false);
            setName(task.name);
            setDate(task.date);
            setTime(task.time);
            setEdit(task.id);
          }}
          data-testid="icon"
          icon={faPenToSquare}
          color="black"
          style={{
            cursor: 'pointer',
            margin: 8,
            paddingRight: 16,
            fontSize: 18,
          }}
        />
        <FontAwesomeIcon
          onClick={() => deleteTask(task.id)}
          data-testid="icon"
          icon={faTrashCan}
          color="black"
          style={{
            cursor: 'pointer',
            marginTop: 8,
            marginBottom: 8,
            fontSize: 18,
          }}
        />
      </div>
    );
  };

  const editTask = (task: any) => (
    <TableRow key="add-task">
      <TableCell component="th" scope="row">
        <div>
          <TextField
            disabled={updateTaskLoader}
            defaultValue={name}
            onChange={(e) => setName(e.target.value)}
            sx={{
              margin: 0,
              padding: 0,
            }}
            id="outlined-basic"
            label="Task Information"
            variant="outlined"
          />
        </div>
      </TableCell>
      <TableCell>
        <AddDate
          date={date}
          setDate={setDate}
          minDate={dateFrom}
          maxDate={dateTo}
        />
      </TableCell>
      <TableCell>
        <AddTime setTime={setTime} time={time} />
      </TableCell>
      <TableCell>
        <div
          role="presentation"
          style={{
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          {updateTaskLoader ? (
            <Oval width="100" color="black" />
          ) : (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  padding: 16,
                }}
              >
                <FontAwesomeIcon
                  onClick={() => updateTask(task.id)}
                  data-testid="icon"
                  icon={faSquarePlus}
                  color="black"
                  style={{
                    fontSize: 23,
                  }}
                />
              </div>
              <div
                style={{
                  padding: `8px 0px 8px 0px`,
                }}
              >
                <FontAwesomeIcon
                  onClick={() => clearTask()}
                  data-testid="icon"
                  icon={faRectangleXmark}
                  color="black"
                  style={{
                    fontSize: 23,
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </TableCell>
    </TableRow>
  );

  const taskRow = (task: any) => (
    <TableRow key={task.id}>
      <TableCell component="th" scope="row">
        {task.name}
      </TableCell>
      <TableCell align="right">{moment(task.date).format('MMM Do')}</TableCell>
      <TableCell align="right">{moment(task.time).format('LT')}</TableCell>
      <TableCell align="right">{actions(task)}</TableCell>
    </TableRow>
  );
  return (
    <div
      style={{
        display: 'flex',
        marginTop: 32,
        height: `calc(100% - 130px)`,
        width: '100%',
      }}
    >
      <TableContainer>
        <Table sx={{ minWidth: '100%', overflow: 'auto' }}>
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  borderTopLeftRadius: 9,
                  backgroundColor: '#bae1ff',
                  fontWeight: 'bold',
                }}
              >
                Task
              </TableCell>
              <TableCell
                style={{
                  fontWeight: 'bold',
                  backgroundColor: '#bae1ff',
                }}
                align="right"
              >
                Date
              </TableCell>
              <TableCell
                style={{
                  fontWeight: 'bold',
                  backgroundColor: '#bae1ff',
                }}
                align="right"
              >
                Time
              </TableCell>
              <TableCell
                style={{
                  borderTopRightRadius: 9,
                  fontWeight: 'bold',
                  backgroundColor: '#bae1ff',
                }}
                align="right"
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tasks.length > 0 &&
              tasks.map((task) => (
                <>
                  {task.id === edit && editTask(task)}
                  {edit === null && taskRow(task)}
                </>
              ))}
            {add ? (
              <TableRow key="add-task">
                <TableCell component="th" scope="row">
                  <div>
                    <TextField
                      onChange={(e) => setName(e.target.value)}
                      sx={{
                        margin: 0,
                        padding: 0,
                      }}
                      id="outlined-basic"
                      label="Task Information"
                      variant="outlined"
                    />
                  </div>
                </TableCell>
                <TableCell>
                  <AddDate
                    date={date}
                    setDate={setDate}
                    minDate={dateFrom}
                    maxDate={dateTo}
                  />
                </TableCell>
                <TableCell>
                  <AddTime setTime={setTime} time={time} />
                </TableCell>
                <TableCell>
                  {updateTaskLoader ? (
                    <Oval width="100" color="black" />
                  ) : (
                    <div
                      role="presentation"
                      style={{
                        cursor: 'pointer',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          padding: 16,
                        }}
                      >
                        <FontAwesomeIcon
                          onClick={() => addTask()}
                          data-testid="icon"
                          icon={faSquarePlus}
                          color="black"
                          style={{
                            fontSize: 23,
                          }}
                        />
                      </div>
                      <div
                        style={{
                          padding: `8px 0px 8px 0px`,
                        }}
                      >
                        <FontAwesomeIcon
                          onClick={() => clearTask()}
                          data-testid="icon"
                          icon={faRectangleXmark}
                          color="black"
                          style={{
                            fontSize: 23,
                          }}
                        />
                      </div>
                    </div>
                  )}
                </TableCell>
              </TableRow>
            ) : (
              <TableRow key="add-task">
                <TableCell component="th" scope="row" />
                <TableCell />
                <TableCell />
                <TableCell>
                  <div
                    role="presentation"
                    onClick={() => {
                      setEdit(null);
                      setAdd(true);
                    }}
                    style={{
                      cursor: 'pointer',
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    <FontAwesomeIcon
                      data-testid="icon"
                      icon={faSquarePlus}
                      color="black"
                      style={{
                        marginTop: 8,
                        marginBottom: 8,
                        marginRight: 8,
                        fontSize: 18,
                      }}
                    />
                    <div>Add Task</div>
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
