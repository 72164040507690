import React from 'react';
import Text from '../../Components/Text/BaseText';

export default function Social() {
  const styles = {
    icon: {
      padding: 8,
      cursor: 'pointer',
      fontSize: 22,
      color: 'white',
    },
  };
  return (
    <div
      style={{
        paddingTop: 24,
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          borderTop: `1px solid white`,
          borderBottom: `1px solid white`,
          width: '90%',
        }}
      >
        <Text
          style={{
            paddingTop: 16,
            margin: 0,
            textAlign: 'center',
            fontWeight: 'bold',
            color: 'snow',
          }}
        >
          FOLLOWING
        </Text>
        <Text
          style={{
            paddingBottom: 16,
            margin: 0,
            fontSize: 26,
            textAlign: 'center',
            fontWeight: 200,
            color: 'snow',
          }}
        >
          22,024
        </Text>
      </div>
      <div
        style={{
          borderTop: `1px solid white`,
          borderBottom: `1px solid white`,
          width: '90%',
          color: 'snow',
        }}
      >
        <Text
          style={{
            paddingTop: 16,
            margin: 0,
            textAlign: 'center',
            fontWeight: 'bold',
            color: 'snow',
          }}
        >
          VIEWS
        </Text>
        <Text
          style={{
            paddingBottom: 16,
            margin: 0,
            fontSize: 26,
            textAlign: 'center',
            fontWeight: 200,
            color: 'snow',
          }}
        >
          90,592
        </Text>
      </div>
    </div>
  );
}
