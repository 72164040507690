export const METHOD = {
  POST: 'post',
  GET: 'get',
};

export const Headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
};

// export const URL = 'http://localhost:3000';

export const URL = 'https://eva-services.vercel.app';
