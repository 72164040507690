import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendar,
  faBoxArchive,
  faEye,
  faHammer,
} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import HeaderText from '../../Components/Text/HeaderText';
import Search from '../Search';
import DeviceQuery from '../../Utilities/DeviceQuery';
import VisitedProfiles from './VisitedProfiles';
import SavedProfiles from './SavedProfiles';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Overview = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  @media (${DeviceQuery.mobile}) {
    flex-direction: column;
  }
`;

const Card = styled.div.attrs((props: { color: string }) => props)`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 90px;
  margin-bottom: 10px;
  width: 24%;
  padding: 16px;
  border-radius: 4px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: ${(props) => props.color};
  @media (${DeviceQuery.tablet}) {
    width: 24%;
  }
  @media (${DeviceQuery.mobile}) {
    width: 100%;
  }
`;

const SummaryText = styled.div`
  font-size: 14px;
  font-weight: 300px;
  text-align: right;
  @media (${DeviceQuery.mobile}) {
    font-size: 16px;
  }
`;

const BoldText = styled.div`
  font-size: 14px;
  text-align: right;
  font-weight: bold;
  @media (${DeviceQuery.mobile}) {
    font-size: 20px;
  }
`;

type Props = {
  events: any;
  savedBusinesses: any;
  userName: string;
  jobs: number;
};
export default function TopPane({
  jobs,
  savedBusinesses,
  events,
  userName,
}: Props) {
  const navigate = useNavigate();
  const [openVisited, setOpenVisited] = React.useState(false);
  const [openSaved, setOpenSaved] = React.useState(false);

  return (
    <div>
      <VisitedProfiles
        open={openVisited}
        onClose={() => setOpenVisited(false)}
      />
      <SavedProfiles
        savedBusinesses={savedBusinesses}
        open={openSaved}
        onClose={() => setOpenSaved(false)}
      />
      <Header>
        <HeaderText>Hey {userName}</HeaderText>
        <Search />
      </Header>
      <Overview>
        <Card onClick={() => navigate('/events')} color="#75b4d2">
          <FontAwesomeIcon
            icon={faCalendar}
            style={{
              color: '#0000005e',
              fontSize: 26,
            }}
          />
          {(events === null || events.length === 0) && (
            <div>
              <SummaryText>Lets Setup Your</SummaryText>
              <BoldText>Next Event</BoldText>
            </div>
          )}
          {events !== null && events.length > 0 && (
            <div>
              <SummaryText>
                {moment(events[0].dateFrom).fromNow(true)} remaining
              </SummaryText>
              <BoldText>{events[0].title}</BoldText>
            </div>
          )}
        </Card>
        <Card onClick={() => navigate('/jobs')} color="#ffb3ba">
          <FontAwesomeIcon
            icon={faHammer}
            style={{
              color: '#0000005e',
              fontSize: 26,
            }}
          />
          <div>
            {jobs === 0 && <SummaryText>No Jobs Posted</SummaryText>}
            {jobs === 1 && <SummaryText>{jobs} Job Posted</SummaryText>}
            {jobs > 1 && <SummaryText>{jobs} Jobs Posted</SummaryText>}
            {jobs > 0 && <BoldText>0 Responses</BoldText>}
          </div>
        </Card>
        <Card onClick={() => setOpenVisited(true)} color="#F5BE9A">
          <FontAwesomeIcon
            icon={faEye}
            style={{
              color: '#0000005e',
              fontSize: 26,
            }}
          />
          <div>
            <SummaryText>View Your Previous</SummaryText>
            <BoldText>Business Profiles</BoldText>
          </div>
        </Card>
        <Card onClick={() => setOpenSaved(true)} color="#CAF3DE">
          <FontAwesomeIcon
            icon={faBoxArchive}
            style={{
              color: '#0000005e',
              fontSize: 26,
            }}
          />
          <div>
            <SummaryText>{savedBusinesses.length} Saved</SummaryText>
            <BoldText>Business Profiles</BoldText>
          </div>
        </Card>
      </Overview>
    </div>
  );
}
