import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import ReactPlayer from 'react-player';
import useWindowDimensions from '../../Utilities/WindowDetection';
import DeviceQuery from '../../Utilities/DeviceQuery';

const style = {
  zIndex: 9999,
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'black',
  border: '2px solid #ffffff0',
  //   boxShadow: 24,
  //   pt: 2,
  //   px: 4,
  //   pb: 3,
  borderTopLeftRadius: 10,
  borderTopRightRadius: 10,

  //   padding: 2,
};

const Tab = styled.div<{
  $selected: number;
  $value: number;
}>`
  cursor: pointer;
  padding: 16px;
  width: 100%;
  background-color: ${(props) =>
    props.$selected === props.$value ? `lightgrey` : `darkgrey`};
  text-align: center;
  color: ${(props) => (props.$selected === props.$value ? `black` : `grey`)};
  font-weight: ${(props) => (props.$selected === props.$value ? `400` : `200`)};
  border-bottom: ${(props) =>
    props.$selected === props.$value
      ? `4px solid purple`
      : `4px solid darkgrey`};
`;

const Button = styled.div`
  cursor: pointer;
  position: absolute;
  top: 14px;
  right: 14px;
  z-index: 9999;
  border-radius: 9999px;
  width: 120px;
  background-color: white;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

const Crop = styled.div`
  width: 100%;
  height: 100%;
  z-index: 9999;
  clip-path: inset(60px 0px 60px 0);
  @media (${DeviceQuery.mobile}) {
    clip-path: inset(0 0 0 0);
    position: unset;
  }
`;
type Props = {
  open: boolean;
  onClose: () => void;
  url: string;
};
export default function YoutubeModal({ open, onClose, url }: Props) {
  const { isMobile } = useWindowDimensions();
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box
        sx={{
          ...style,
          height: isMobile ? '100%' : '90vh',
          width: isMobile ? '100%' : '90%',
          overflow: 'hidden',
        }}
      >
        <Crop>
          <ReactPlayer
            config={{ youtube: { playerVars: { disablekb: 1 } } }}
            height="100%"
            width="100%"
            playing
            url={url}
          />
        </Crop>
        <Button onClick={onClose}>
          <FontAwesomeIcon
            icon={faCircleXmark}
            style={{
              borderTopRightRadius: 8,
              color: 'black',
            }}
          />
          <div style={{ pointerEvents: 'none' }}>Close</div>
        </Button>
      </Box>
    </Modal>
  );
}
