import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import jwt from 'jwt-decode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import InputField from '../../../Components/InputField';
import Button from '../../../Components/Button';
import palette from '../../../Utilities/Palette';
import { getUserDetails, CreateAccount } from '../../../Api/Calls';
import Checkbox from '../../../Components/Checkbox';
import Dropdown from './Dropdown';
import MutatingDots from '../../../Components/MutatingDots';

const Root = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  height: 100%;
  min-width: 500px;
`;

const LoginText = styled.div`
  font-size: 22px;
  font-weight: 500;
  text-align: left;

  @media (max-height: 576px) {
    font-size: 32px;
    font-weight: 500;
    text-align: left;
  }
  @media (max-width: 820px) {
    font-size: 32px;
    font-weight: 500;
    text-align: left;
  }
`;

type Props = {
  onClose: () => void;
  onSuccess: () => void;
};

export default function Register({ onClose, onSuccess }: Props) {
  const navigate = useNavigate();
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [number, setNumber] = React.useState('');
  const [country, setCountry] = React.useState('United Kingdom');
  const [pass, setPass] = React.useState('');
  const [confirmPass, setConfirmPass] = React.useState('');
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [accountType, setAccountType] = React.useState('personal');
  const [loading, setLoading] = React.useState(false);

  function validMail(mail: string) {
    return /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+([^<>()\.,;:\s@\"]{2,}|[\d\.]+))$/.test(
      mail,
    );
  }
  const validate = async () => {
    if (name.length === 0) {
      setError(true);
      setErrorMessage('Name cannot be empty');
    } else if (/[0-9]/.test(name)) {
      setError(true);
      setErrorMessage('Name cannot contain special characters');
    } else if (!validMail(email)) {
      setError(true);
      setErrorMessage('Please enter a valid e-mail address');
    } else if (pass.length <= 6 || confirmPass.length <= 6) {
      setError(true);
      setErrorMessage('Password length must be greater than 6 characters');
    } else if (pass !== confirmPass) {
      setError(true);
      setErrorMessage('Password mismatch');
    } else {
      setLoading(true);
      const { error, token } = await CreateAccount(
        name,
        email,
        number,
        pass,
        country,
        accountType,
      );
      if (error) {
        setLoading(false);
        setError(true);
        setErrorMessage(error);
      } else if (token.length > 0) {
        setLoading(false);
        onSuccess();
        onClose();
        localStorage.setItem('token', token);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const { user } = jwt(token);
        const userDetails = await getUserDetails(user.id);
        const { business_account: business } = userDetails[0];
        localStorage.setItem('admin', business);
        setTimeout(
          () => navigate(business === 0 ? '/home' : '/admin/profile'),
          1500,
        );
      }
    }
  };

  return (
    <Root>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={error}
        autoHideDuration={3000}
        onClose={() => {
          setError(false);
          setErrorMessage('');
        }}
      >
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
      <LoginText>Create Account</LoginText>
      <InputField
        width="100%"
        mobileWidth="100%"
        tabletWidth="100%"
        mandatory
        type="text"
        placeholder="John Doe"
        header="Full Name"
        onChange={setName}
      />
      <InputField
        width="100%"
        mobileWidth="100%"
        tabletWidth="100%"
        mandatory
        placeholder="example@example.com"
        header="Email"
        onChange={setEmail}
      />
      <InputField
        width="100%"
        mobileWidth="100%"
        tabletWidth="100%"
        mandatory
        type="number"
        placeholder="0777-777-7777"
        header="Contact Number"
        onChange={setNumber}
      />
      <Dropdown country={country} setCountry={setCountry} />
      <InputField
        width="100%"
        mobileWidth="100%"
        tabletWidth="100%"
        mandatory
        type="password"
        header="Password"
        onChange={setPass}
      />
      <InputField
        width="100%"
        mobileWidth="100%"
        tabletWidth="100%"
        mandatory
        header="Re-enter Password"
        type="password"
        onChange={setConfirmPass}
      />
      <div
        style={{
          paddingTop: 32,
        }}
      >
        {/* <LoginText>Account Type</LoginText>
        <div
          style={{
            display: 'flex',
            paddingTop: 16,
            paddingBottom: 16,
          }}
        >
          <Checkbox
            title="Personal"
            checked={accountType === 'personal'}
            onClick={() => setAccountType('personal')}
          />
          <div
            style={{
              paddingLeft: 16,
            }}
          >
            <Checkbox
              title="Business"
              checked={accountType === 'business'}
              onClick={() => setAccountType('business')}
            />
          </div>
        </div> */}
        <Button
          icon={
            loading ? null : (
              <FontAwesomeIcon
                icon={faUser}
                color={palette.WHITE}
                style={{ paddingLeft: 20, fontSize: 18 }}
              />
            )
          }
          name={
            loading ? (
              <div
                style={{
                  position: 'absolute',
                  zoom: 0.4,
                  left: '39rem',
                  bottom: 80,
                }}
              >
                <MutatingDots />
              </div>
            ) : (
              'Create Account'
            )
          }
          onClick={loading ? () => null : () => validate()}
        />
      </div>
    </Root>
  );
}
