import React from 'react';
import { useTranslation } from 'react-i18next';
import TextTransition, { presets } from 'react-text-transition';
import { MutatingDots } from 'react-loader-spinner';
import useWindowDimensions from '../Utilities/WindowDetection';

const TITLE = ['P', 'PL', 'PLA', 'PLAN', 'PLAN'];
const END = ['4', '', '', '', '4'];

export default function LogoAnimation() {
  const [index, setIndex] = React.useState(0);
  const { isMobile } = useWindowDimensions();

  React.useEffect(() => {
    const intervalId = setInterval(() => setIndex((index) => index + 1), 750);
    return () => clearTimeout(intervalId);
  }, []);

  const { t } = useTranslation();
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      <MutatingDots
        height="100"
        width="100"
        color="#ffffff"
        secondaryColor="#ff0000"
        radius="12"
        ariaLabel="mutating-dots-loading"
        visible
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <h1
          style={{
            fontSize: 60,
            fontWeight: 'bold',
            color: 'white',
          }}
        >
          <TextTransition
            translateValue="0%"
            inline
            springConfig={presets.stiff}
          >
            {TITLE[index % TITLE.length]}
          </TextTransition>
        </h1>
        <h1
          style={{
            fontWeight: 'bold',
            color: '#cc2434',
            fontSize: 40,
          }}
        >
          <TextTransition inline springConfig={presets.slow}>
            {END[index % END.length]}
          </TextTransition>
        </h1>
      </div>
    </div>
  );
}

LogoAnimation.defaultProps = {
  interval: 3500,
};
