const colors = [
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
  '#747b52',
  '#417a6e',
  '#534d82',
  '#875366',
  '#744b37',
  '#667fb4',
  '#7a3b3b',
  '#693a77',
  '#377a63',
  '#762240',
];

export default colors;
