import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import useWindowDimensions from '../../Utilities/WindowDetection';
import DeviceQuery from '../../Utilities/DeviceQuery';

const fadeIn = keyframes`
0% {
    filter: brightness(0);
  }
 100% {
    filter: brightness(1);
  }
`;

const fadeOut = keyframes`
  0% {
    filter: brightness(1);
  }
  100% {
     filter: brightness(0);
  }
`;

const Image = styled.div.attrs((props: { image: string }) => props)`
  cursor: pointer;
  position: relative;
  height: calc(100% - 250px);
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  background-image: ${(props) => `url(${props.image})`};
  animation: ${fadeIn} 1s 0s ease-out forwards,
    ${fadeOut} 1s 3.5s ease-out forwards;
  @media (${DeviceQuery.mobile}) {
    height: 350px;
    width: 100%;
  }
`;

type Props = {
  service: any;
  images: any[];
};
export default function ServiceDetails({ service, images }: Props) {
  const [activeIndex, setActiveIndex] = React.useState(0);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      if (images.length > 0 && activeIndex >= images.length - 1) {
        setActiveIndex(0);
      } else {
        setActiveIndex(activeIndex + 1);
      }
    }, 4500);

    return () => {
      clearTimeout(timeout);
    };
  }, [images, activeIndex]);

  const { isMobile } = useWindowDimensions();

  if (service === null) {
    return null;
  }

  const { id, service_name, service_desc, subtitle } = service;

  return (
    <>
      <Image key={images[activeIndex]} image={images[activeIndex]} />
      <div
        style={{
          bottom: 0,
        }}
      >
        <div
          style={{
            paddingTop: 4,
            paddingBottom: 4,
            backgroundColor: '#1c1c1cc4',
          }}
        >
          <div
            style={{
              paddingLeft: 16,
              paddingRight: 16,
              color: 'white',
              fontSize: 36,
              fontWeight: '200',
            }}
          >
            {service_name}
          </div>
          <div
            style={{
              paddingLeft: 16,
              paddingRight: 16,
              paddingBottom: 8,
              color: 'white',
              fontSize: 18,
              fontWeight: '300',
            }}
          >
            {subtitle}
          </div>
        </div>
        <div
          style={{
            padding: 16,
            color: 'white',
            fontSize: 16,
            fontWeight: '200',
          }}
        >
          {service_desc}
        </div>
      </div>
    </>
  );
}
