// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
const viewedProfiles = {
  state: {
    profiles: [],
  },
  reducers: {
    addProfile(state: any, profile: any) {
      const { profiles } = state;
      const previouslyClicked = profiles.filter(
        (previousProfile: any) => previousProfile.id === profile.id,
      );
      /**
       * if profile has previously clicked, then dont add to array
       */
      if (previouslyClicked.length > 0) {
        return { ...state };
      }
      if (profiles.length >= 5) {
        return {
          ...state,
          profiles: [
            profile,
            state.profiles[0],
            state.profiles[1],
            state.profiles[2],
            state.profiles[3],
          ],
        };
      }
      return { ...state, profiles: [profile, ...state.profiles] };
    },
  },
};

export default viewedProfiles;
