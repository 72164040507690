import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import styled from 'styled-components';
import { faRectangleXmark } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import DeviceQuery from '../../Utilities/DeviceQuery';
import useWindowDimensions from '../../Utilities/WindowDetection';

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'black',
  border: '2px solid #c7bdbd',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  borderRadius: 2,
  padding: 4,
};

const TextContent = styled.div`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 12px;
  color: white;
`;

const Card = styled.div.attrs((props: { image: string }) => props)`
  margin: 8px;
  cursor: pointer;
  position: relative;
  height: 300px;
  width: 300px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: ${(props) => `url(${props.image})`};
  @media (${DeviceQuery.mobile}) {
    zoom: 0.85;
    margin: 8px;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: scroll;
  @media (${DeviceQuery.mobile}) {
    flex-direction: column;
  }
`;

type Props = {
  open: boolean;
  onClose: () => void;
  savedBusinesses: any;
};
export default function ChildModal({ savedBusinesses, open, onClose }: Props) {
  const { isMobile } = useWindowDimensions();
  const navigate = useNavigate();
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box
        sx={{
          ...style,
          height: isMobile ? '100%' : '70%',
          width: isMobile ? '100%' : 'unset',
          overflow: 'scroll',
        }}
      >
        <div
          style={{
            height: 60,
          }}
        >
          <h2
            style={{
              color: 'white',
              paddingLeft: 8,
              width: '70%',
              fontSize: 20,
            }}
            id="child-modal-title"
          >
            Saved Profiles
          </h2>
          <FontAwesomeIcon
            icon={faRectangleXmark}
            color="black"
            style={{
              padding: 16,
              fontSize: 32,
              position: 'absolute',
              top: 12,
              right: 24,
              cursor: 'pointer',
              color: 'white',
            }}
            onClick={onClose}
          />
        </div>
        <Content>
          {savedBusinesses.length === 0 && (
            <div
              style={{
                color: 'white',
              }}
            >
              No viewed profiles
            </div>
          )}
          {savedBusinesses.length > 0 &&
            savedBusinesses.map((profile: any) => (
              <Card
                image={profile.image}
                onClick={() =>
                  navigate('/business', { state: { business: profile } })
                }
              >
                <div
                  style={{
                    paddingLeft: `10px`,
                    paddingRight: `10px`,
                    position: 'absolute',
                    bottom: 0,
                    height: 100,
                    width: '100%',
                    backgroundColor: '#373737db',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      height: '100%',
                    }}
                  >
                    <div
                      style={{
                        fontWeight: 'bold',
                        paddingBottom: 4,
                        fontSize: 16,
                        color: 'white',
                      }}
                    >
                      {profile.name}
                    </div>
                    <TextContent>{profile.profile}</TextContent>
                  </div>
                </div>
              </Card>
            ))}
        </Content>
      </Box>
    </Modal>
  );
}
