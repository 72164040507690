import React from 'react';
import styled from 'styled-components';
import { faHeart as faHeartEmpty } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import jwt from 'jwt-decode';
import Text from '../../Components/Text/BaseText';
import DeviceQuery from '../../Utilities/DeviceQuery';
import {
  unfavouritiseBusiness,
  favouritiseBusiness,
  isBusinessFavourited,
} from '../../Api/Calls';

const FollowButton = styled.div.attrs((props: { darkMode: boolean }) => props)`
  cursor: pointer;
  position: absolute;
  bottom: 28px;
  right: 28px;
  background-image: ${(props) =>
    props.darkMode
      ? `linear-gradient(to right, #99376f, #803474)`
      : `linear-gradient(to right, #376099, #345f80)`};
  width: 180px;
  height: 44px;
  border-radius: 12px;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  @media (${DeviceQuery.tablet}) {
    zoom: 0.8;
  }
  @media (${DeviceQuery.mobile}) {
    right: 36px;
    border-radius: 12px;
    zoom: 0.75;
    top: 36px;
  }
`;

const FollowButtonContent = styled.div`
  padding: 8px;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

type Props = {
  businessId: number;
};
export default function Follow({ businessId }: Props) {
  const [loading, setLoading] = React.useState(false);
  const [favourited, setFavourited] = React.useState(false);
  const token = localStorage.getItem('token');
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { user } = jwt(token);

  const getData = async () => {
    setLoading(true);
    const { rows } = await isBusinessFavourited(user.id, businessId);
    if (rows.length > 0) {
      setFavourited(true);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    getData();
  }, []);

  const favouritise = async () => {
    setFavourited(true);
    await favouritiseBusiness(user.id, businessId);
  };

  const unfavouritise = async () => {
    setFavourited(false);
    await unfavouritiseBusiness(user.id, businessId);
  };

  const { darkMode } = useSelector((state: any) => state.Config);

  if (loading) {
    return <FollowButton darkMode={darkMode} />;
  }
  return (
    <FollowButton
      onClick={async () => (favourited ? unfavouritise() : favouritise())}
      darkMode={darkMode}
    >
      <FollowButtonContent>
        <FontAwesomeIcon
          icon={favourited ? faHeartEmpty : faHeartEmpty}
          color="white"
          style={{
            marginLeft: 8,
            fontSize: 18,
          }}
        />
        <Text
          style={{
            fontSize: 18,
            paddingRight: 16,
            color: 'white',
          }}
        >
          {favourited ? `Favourited` : `Favourite`}
        </Text>
      </FollowButtonContent>
    </FollowButton>
  );
}
