export default {
  PRIMARY: '#5C1AB7',
  PRIMARY_DARK: '#1A0550',
  PRIMARY_SHADE: '#4E37CC',
  PRIMARY_LIGHT: '#F8F6FC',
  PRIMARY_HINT: '#f6EFFF',
  SECONDARY: '#005A6F',
  SECONDARY_LIGHT: '#89D5E3',
  SECONDARY_HINT: '#F0F8F8',
  HIGHLIGHT: '#FFCEF9',
  INDICATOR_GREEN: '#0CC190',
  INDICATOR_RED: '#D62655',
  INDICATOR_LINK_BLUE: '#111EBA',
  INDICATOR_GREEN_LIGHT: '#E5F8F3',
  INDICATOR_RED_LIGHT: '#FDE7ED',
  OFF_BLACK: '#2A2931',
  GREY_DARK: '#3A394E',
  GREY_LIGHT: '#E8E7F2',
  WHITE: '#ffffff',
  BLACK: '#000000',
  GREY: '#808080',
  LIGHT_GREY: '#C9C9CE',
  BOX_SHADOW: '#00000029',
  OFF_WHITE: '#EFF0FC33',
};
