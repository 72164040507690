import React from 'react';
import {
  Button,
  CalendarCell,
  CalendarGrid,
  Heading,
  RangeCalendar,
} from 'react-aria-components';
import { today, getLocalTimeZone } from '@internationalized/date';

import EaseIn from '../../../Components/EaseIn';

import './styles.css';

type Props = {
  range: any;
  setRange: (range: any) => void;
};

export default function DateSelector({ range, setRange }: Props) {
  return (
    <EaseIn>
      <div style={{}}>
        <h1
          style={{
            fontSize: 22,
            textAlign: 'center',
          }}
        >
          Ok Great!
        </h1>
        <p
          style={{
            fontSize: 17,
            textAlign: 'center',
          }}
        >
          Please select the date range or day the event will happen:
        </p>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <RangeCalendar
            minValue={today(getLocalTimeZone())}
            value={range}
            onChange={setRange}
            aria-label="Trip dates"
          >
            <header>
              <Button slot="previous">◀</Button>
              <Heading />
              <Button slot="next">▶</Button>
            </header>
            <CalendarGrid>
              {(date) => <CalendarCell date={date} />}
            </CalendarGrid>
          </RangeCalendar>
        </div>
        <div
          style={{
            paddingTop: 16,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        />
      </div>
    </EaseIn>
  );
}
