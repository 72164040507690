import React from 'react';
import { MutatingDots } from 'react-loader-spinner';
import { useSelector } from 'react-redux';

export default function Mutation() {
  const { darkMode } = useSelector((state: any) => state.Config);

  return (
    <MutatingDots
      height="100"
      width="100"
      color={darkMode ? '#ffffff' : 'black'}
      secondaryColor="#ff0000"
      radius="12"
      ariaLabel="mutating-dots-loading"
      visible
    />
  );
}
