import * as React from 'react';
import TextField from '@mui/material/TextField';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import {
  faUser,
  faCar,
  faFilm,
  faChurch,
  faBuilding,
  faRing,
  faMusic,
  faMicrophone,
  faUtensils,
  faStar,
  faHeart,
  faCalendarDays,
  faCartShopping,
  faPen,
  faBook,
  faVideo,
  faEye,
  faCity,
  faHandshake,
  faStreetView,
  faHouse,
  faThumbtack,
  faSquare,
  faCircle,
  faCircleNodes,
  faShapes,
  faUsersRectangle,
  faList,
  faHandsPraying,
  faGift,
  faQuestion,
  faUserTie,
  faVenusMars,
  faLocationDot,
  faComment,
  faComments,
  faMinus,
  faMoon,
  faMugSaucer,
} from '@fortawesome/free-solid-svg-icons';
import EaseIn from '../../../Components/EaseIn';
import DeviceQuery from '../../../Utilities/DeviceQuery';

const IconContainer = styled.div`
  &:hover {
    filter: contrast(100);
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  @media (${DeviceQuery.mobile}) {
    height: 200px;
    overflow-y: scroll;
  }
`;

export const icons = [
  {
    name: 'faHouse',
    icon: faHouse,
  },
  {
    name: 'faUser',
    icon: faUser,
  },
  {
    name: 'faCar',
    icon: faCar,
  },
  {
    name: 'faFilm',
    icon: faFilm,
  },
  {
    name: 'faChurch',
    icon: faChurch,
  },
  {
    name: 'faBuilding',
    icon: faBuilding,
  },
  {
    name: 'faRing',
    icon: faRing,
  },
  {
    name: 'faMusic',
    icon: faMusic,
  },
  {
    name: 'faMicrophone',
    icon: faMicrophone,
  },
  {
    name: 'faUtensils',
    icon: faUtensils,
  },
  {
    name: 'faStar',
    icon: faStar,
  },
  {
    name: 'faHeart',
    icon: faHeart,
  },
  {
    name: 'faCalendarDays',
    icon: faCalendarDays,
  },
  {
    name: 'faCartShopping',
    icon: faCartShopping,
  },
  {
    name: 'faPen',
    icon: faPen,
  },
  {
    name: 'faBook',
    icon: faBook,
  },
  {
    name: 'faVideo',
    icon: faVideo,
  },
  {
    name: 'faEye',
    icon: faEye,
  },
  {
    name: 'faCity',
    icon: faCity,
  },
  {
    name: 'faHandshake',
    icon: faHandshake,
  },

  {
    name: 'faStreetView',
    icon: faStreetView,
  },
  {
    name: 'faThumbtack',
    icon: faThumbtack,
  },
  {
    name: 'faSquare',
    icon: faSquare,
  },
  {
    name: 'faCircle',
    icon: faCircle,
  },
  {
    name: 'faCircleNodes',
    icon: faCircleNodes,
  },
  {
    name: 'faShapes',
    icon: faShapes,
  },
  {
    name: 'faUsersRectangle',
    icon: faUsersRectangle,
  },
  {
    name: 'faList',
    icon: faList,
  },
  {
    name: 'faHandsPraying',
    icon: faHandsPraying,
  },
  {
    name: 'faGift',
    icon: faGift,
  },
  {
    name: 'faQuestion',
    icon: faQuestion,
  },
  {
    name: 'faUserTie',
    icon: faUserTie,
  },
  {
    name: 'faVenusMars',
    icon: faVenusMars,
  },
  {
    name: 'faLocationDot',
    icon: faLocationDot,
  },
  {
    name: 'faComment',
    icon: faComment,
  },
  {
    name: 'faComments',
    icon: faComments,
  },
  {
    name: 'faMinus',
    icon: faMinus,
  },
  {
    name: 'faMoon',
    icon: faMoon,
  },
  {
    name: 'faMugSaucer',
    icon: faMugSaucer,
  },
];

type Props = {
  icon: any;
  setIcon: (icon: string) => void;
};

export default function SelectIcon({ icon: selectedIcon, setIcon }: Props) {
  return (
    <EaseIn>
      <h1
        style={{
          fontSize: 22,
          textAlign: 'center',
        }}
      >
        Good to know!
      </h1>
      <p
        style={{
          fontSize: 17,
          textAlign: 'center',
        }}
      >
        Select an icon for your event.
      </p>
      <Container>
        {icons.map((icon: any) => (
          <IconContainer>
            <FontAwesomeIcon
              onClick={() => setIcon(icon)}
              icon={icon.icon}
              color="white"
              style={{
                border:
                  selectedIcon?.name === icon.name
                    ? '4px solid red'
                    : '0px solid',
                cursor: 'pointer',
                background:
                  selectedIcon?.name === icon.name
                    ? 'black'
                    : `linear-gradient(112.1deg, rgb(32, 38, 57) 11.4%, rgb(63, 76, 119) 70.2%)`,
                boxShadow: `rgba(100, 100, 111, 0.2) 0px 7px 29px 0px`,
                width: selectedIcon?.name === icon.name ? 28 : 30,
                height: selectedIcon?.name === icon.name ? 28 : 30,
                borderRadius: 8,
                padding: 12,
                margin: 8,
              }}
            />
          </IconContainer>
        ))}
      </Container>
    </EaseIn>
  );
}
