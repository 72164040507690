import React, { ReactNode, CSSProperties } from 'react';
import { useSelector } from 'react-redux';
import '../../App.css';

type BaseTextProps = {
  children: string | ReactNode;
  style?: CSSProperties;
};

export default function BaseText({ children, style }: BaseTextProps) {
  const { darkMode } = useSelector((state: any) => state.Config);
  const defaultProps = {
    style: {
      fontFamily: 'Lexend',
      fontSize: 14,
      color: darkMode ? 'white' : 'black',
    },
  };
  return <div style={{ ...defaultProps.style, ...style }}>{children}</div>;
}
