import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import DeviceQuery from '../../../Utilities/DeviceQuery';

const Container = styled.div.attrs((props: { darkMode: boolean }) => props)`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 98%;
  zoom: 0.9;
  background-color: ${(props) => (props.darkMode ? `#391331` : `#213d61`)};
  border-bottom-right-radius: 30px;

  @media (${DeviceQuery.mobile}) {
    zoom: 1;
    width: 100vw;
    max-width: 100vw;
    border-bottom-right-radius: 0px;
  }
`;

type Props = {
  tab: number;
  setTab: (tab: number) => void;
};
export default function BusinessProfile({ tab, setTab }: Props) {
  const { darkMode } = useSelector((state: any) => state.Config);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const styles = {
    selected: {
      minWidth: 50,
      fontSize: 12,
      backgroundColor: darkMode ? '#5a204e' : '#355f81',
      color: 'white',
    },
    unselected: {
      minWidth: 50,
      fontSize: 12,
      backgroundColor: darkMode ? '#391331' : '#213d61',
      color: darkMode ? 'grey' : '#b2b2b2',
    },
  };
  return (
    <Container darkMode={darkMode}>
      <Tabs
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        aria-label="scrollable force tabs example"
        value={tab}
        onChange={handleChange}
      >
        <Tab
          style={tab === 0 ? styles.selected : styles.unselected}
          label="Profile"
        />
        <Tab
          style={tab === 1 ? styles.selected : styles.unselected}
          label="Gallery"
        />
        <Tab
          style={tab === 2 ? styles.selected : styles.unselected}
          label="Reels"
        />
        <Tab
          style={tab === 3 ? styles.selected : styles.unselected}
          label="Services"
        />
      </Tabs>
    </Container>
  );
}
