import React from 'react';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';

type Props = {
  setTime: (time: string) => void;
  time: string;
};
export default function JobDrawer({ setTime, time }: Props) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MobileTimePicker
        label="Time"
        value={time}
        onChange={setTime}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
  );
}
