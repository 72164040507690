import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import DeviceQuery from '../../Utilities/DeviceQuery';
import '../../App.css';

type HeaderTextProps = {
  children: string | ReactNode;
};

const Header = styled.div.attrs((props: { darkMode: boolean }) => props)`
  color: ${(props) => (props.darkMode ? 'white' : 'black')};
  padding-top: 2px;
  padding-bottom: 12px;
  font-size: 24px;
  font-family: Lexend;
  font-weight: 200;
  @media (${DeviceQuery.tablet}) {
    font-size: 16px;
  }
`;

export default function HeaderText({ children }: HeaderTextProps) {
  const { darkMode } = useSelector((state: any) => state.Config);
  return <Header darkMode={darkMode}>{children}</Header>;
}
