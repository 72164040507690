import { createModel } from '@rematch/core';
import type { RootModel } from './RootModel';

const Dashboard = createModel()({
  state: {
    openMenu: false,
  },
  reducers: {
    setMenu(state: any) {
      const { openMenu } = state;
      return { ...state, openMenu: !openMenu };
    },
    closeMenu(state: any) {
      return { ...state, openMenu: false };
    },
  },
});

export default Dashboard;
