const config = {
  state: {
    darkMode: true,
  },
  reducers: {
    setMode(state: any, darkMode: boolean) {
      return { ...state, darkMode };
    },
  },
};

export default config;
