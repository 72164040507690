import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';

type Props = {
  field: any;
  onClick: (val: any) => void;
  selected: string;
};
export default function FieldButtonGroup({ field, onClick, selected }: Props) {
  const { values } = field;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        '& > *': {
          marginTop: 2,
        },
      }}
    >
      <ButtonGroup variant="outlined" aria-label="outlined button group">
        {values.map((value: any) => (
          <Button
            style={{
              height: 50,
            }}
            variant={value === selected ? 'contained' : 'outlined'}
            onClick={() =>
              onClick({
                id: field.id,
                question: field.title,
                value,
              })
            }
          >
            {value}
          </Button>
        ))}
      </ButtonGroup>
    </Box>
  );
}
