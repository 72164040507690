import React from 'react';
import jwt from 'jwt-decode';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquarePlus } from '@fortawesome/free-regular-svg-icons';
import { useSelector } from 'react-redux';
import Dashboard from '../../Containers/Dashboard';
import Button from '../../Components/Button';
import useWindowDimensions from '../../Utilities/WindowDetection';
import AddJob from './AddJob';
import EditJob from './EditJob';
import { GetJobs, DeleteJob } from '../../Api/Calls';
import JobCard from './JobCard';
import Text from '../../Components/Text/BaseText';
import Colors from './Colors';
import DeviceQuery from '../../Utilities/DeviceQuery';
import MutatingDots from '../../Components/MutatingDots';
import ViewJob from './ViewJob';

export default function Jobs() {
  const { darkMode } = useSelector((state: any) => state.Config);

  const [loading, setLoading] = React.useState(false);
  const token = localStorage.getItem('token');
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { user } = jwt(token);
  const { isMobile } = useWindowDimensions();
  const [open, setOpen] = React.useState(false);
  const [jobs, setJobs] = React.useState([]);
  const [openJob, setOpenJob] = React.useState(false);
  const [displayJob, setDisplayJob] = React.useState({});
  const [editJob, setEditJob] = React.useState<any>({});
  const [deletingId, setDeletingId] = React.useState(null);

  const deleteJob = async (jobId: number) => {
    setDeletingId(jobId);
    const { rows } = await DeleteJob(user.id, jobId);
    setJobs(rows);
    setEditJob({});
    setDeletingId(null);
  };

  const getData = async () => {
    setLoading(true);
    const { rows } = await GetJobs(user.id);
    setJobs(rows);
    setLoading(false);
  };

  React.useEffect(() => {
    getData();
  }, []);

  const JobCardButton = styled.div`
    flex-direction: column;
    margin: 0px 0px 16px 16px;
    position: relative;
    cursor: pointer;
    padding: 16px;
    width: 300px;
    height: 220px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    @media (${DeviceQuery.tablet}) {
      width: calc(50vw - 2.2rem);
    }
    @media (${DeviceQuery.mobile}) {
      width: calc(100vw - 32px);
    }
  `;

  const Loader = styled.div`
    display: flex;
    width: 90vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    @media (${DeviceQuery.tablet}) {
      zoom: 1;
      width: 100%;
      overflow: hidden;
      position: fixed;
      height: 90vh;
    }
  `;

  if (loading) {
    return (
      <Dashboard>
        <Loader>
          <MutatingDots />
        </Loader>
      </Dashboard>
    );
  }
  if (!loading && jobs !== null && jobs.length > 0) {
    return (
      <Dashboard>
        <AddJob setJobs={setJobs} setOpen={setOpen} open={open} />
        <ViewJob
          job={displayJob}
          open={openJob}
          onClose={() => setOpenJob(false)}
        />
        <EditJob
          job={editJob}
          onClose={() => setEditJob({})}
          setJobs={setJobs}
          open={Object.keys(editJob).length > 0}
        />
        <div
          style={{
            color: darkMode ? 'white' : 'black',
            fontSize: 32,
            padding: 16,
          }}
        >
          Jobs
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
          }}
        >
          {jobs.map((job: any, i) => (
            <JobCard
              onClick={() => {
                setOpenJob(true);
                setDisplayJob(job);
              }}
              onEdit={() => setEditJob(job)}
              deletingId={deletingId}
              onDelete={() => deleteJob(job.id)}
              job={job}
              color={darkMode ? Colors.darkColors[i] : Colors.colors[i]}
            />
          ))}
          <JobCardButton
            style={{
              backgroundColor: darkMode ? '#9F223B' : '#FFEA7B',
            }}
            onClick={() => setOpen(true)}
          >
            <FontAwesomeIcon
              icon={faSquarePlus}
              color={darkMode ? 'white' : 'black'}
              style={{ fontSize: 40, padding: 10 }}
            />
            <Text
              style={{
                fontSize: 20,
              }}
            >
              Create a new job
            </Text>
          </JobCardButton>
        </div>
      </Dashboard>
    );
  }

  return (
    <Dashboard>
      <AddJob setJobs={setJobs} setOpen={setOpen} open={open} />
      <div
        style={{
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            zoom: isMobile ? 0.8 : 1,
            height: '100vh',
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1,
          }}
        >
          <div
            style={{
              minWidth: 400,
              width: '30%',
              height: 400,
              padding: 36,
              backgroundColor: '#cc9cd4eb',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              justifyContent: 'space-evenly',
              borderRadius: 8,
            }}
          >
            <div
              style={{
                color: 'black',
                fontWeight: 'bold',
                fontSize: 20,
              }}
            >
              Create a Job Listing
            </div>
            <div
              style={{
                textAlign: 'center',
                fontSize: 16,
              }}
            >
              Here you can post a tailored request for your special event, and
              our business members will be alerted to see if they can fulfil
              your request
            </div>
            <Button name="Create Job" onClick={() => setOpen(true)} />
          </div>
        </div>
      </div>
    </Dashboard>
  );
}
