import React from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { isJwtExpired } from 'jwt-check-expiration';
import { updatePass } from '../../Api/Calls';

export default function ResetPassword() {
  const navigate = useNavigate();
  const urls = window.location.pathname.split('/');
  const token = urls[urls.length - 1];
  const isExpired = isJwtExpired(token);
  const pwdFilter = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  const [pass, setPass] = React.useState('');
  const [confirmPass, setConfirmPass] = React.useState('');
  const [error, setError] = React.useState('');
  const [confirm, setConfirm] = React.useState(false);
  const [disable, setDisable] = React.useState(false);

  const confirmPassword = async () => {
    if (pass !== confirmPass) {
      return setError('Passwords do not match');
    }
    if (pass.length <= 8) {
      return setError('Password length must be more than 8 characters');
    }

    if (!pwdFilter.test(pass)) {
      return setError(
        'Password must contain an upper character, lower character, a digit and symbol',
      );
    }
    setDisable(true);
    setError('');
    const res = await updatePass(token, pass);
    if (res) {
      setConfirm(true);
      setTimeout(() => {
        navigate('/');
      }, 2000);
    } else {
      setError(error);
      setDisable(false);
    }
  };
  if (isExpired) {
    return (
      <div
        style={{
          height: '96vh',
          width: '100vw',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            width: 290,
            paddingTop: 6,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <h1
              style={{
                fontSize: 60,
                fontWeight: 'bold',
                color: 'black',
                paddingRight: 4,
              }}
            >
              PLAN
            </h1>
            <h1
              style={{
                fontWeight: 'bold',
                color: 'red',
                fontSize: 30,
              }}
            >
              4
            </h1>
          </div>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            Token has expired - please request a new reset password link
          </div>
        </div>
      </div>
    );
  }
  return (
    <div
      style={{
        height: '96vh',
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          width: 290,
          paddingTop: 6,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <h1
            style={{
              fontSize: 60,
              fontWeight: 'bold',
              color: 'black',
              paddingRight: 4,
            }}
          >
            PLAN
          </h1>
          <h1
            style={{
              fontWeight: 'bold',
              color: 'red',
              fontSize: 30,
            }}
          >
            4
          </h1>
        </div>
        <div
          style={{
            textAlign: 'center',
          }}
        >
          Reset Password:
        </div>
        {confirm ? (
          <div
            style={{
              paddingTop: 16,
              fontSize: 18,
              textAlign: 'center',
              color: 'darkgreen',
            }}
          >
            Password successfully reset, Re directing...
          </div>
        ) : (
          <>
            <TextField
              disabled={disable}
              onChange={(e) => setPass(e.target.value)}
              style={{ marginTop: 16, marginBottom: 16 }}
              id="filled-basic"
              label="New Password"
              variant="filled"
              type="password"
            />
            <TextField
              disabled={disable}
              onChange={(e) => setConfirmPass(e.target.value)}
              id="filled-basic"
              label="Confirm  Password"
              variant="filled"
              type="password"
            />

            <Button
              disabled={disable}
              onClick={() => confirmPassword()}
              style={{ marginTop: 16 }}
              variant="contained"
            >
              Confirm
            </Button>
            <div
              style={{
                marginTop: 8,
                height: 40,
                textAlign: 'center',
                fontSize: 16,
                color: 'red',
              }}
            >
              {error}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
