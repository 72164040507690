import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import styled from 'styled-components';
import { parseDate } from '@internationalized/date';
import moment from 'moment';
import jwt from 'jwt-decode';
import useWindowDimensions from '../../Utilities/WindowDetection';
import TaskList from './TaskList';
import DeviceQuery from '../../Utilities/DeviceQuery';

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  //   bgcolor: 'white',
  border: '2px solid #ffffff0',
  //   boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  borderRadius: 2,
  padding: 2,
};

const Button = styled.button<{
  $buttonColor: string;
  $disabled: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 50px;
  background-color: ${(props) =>
    props.$disabled ? `#e8e7f2` : props.$buttonColor};
  border-radius: 4px;
  border: 0rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`;

const ButtonOutline = styled.button<{
  $disabled: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 50px;
  background-color: white;
  border-radius: 4px;
  border: 1px solid blue;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`;

const SidePanel = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  min-width: 270px;
  background-color: #bae1ff;
  @media (${DeviceQuery.mobile}) {
    display: none;
  }
`;
type Props = {
  event: any;
  open: boolean;
  onClose: () => void;
};

export default function AddTask({ event, open, onClose }: Props) {
  if (!open) {
    return null;
  }
  const { title, dateFrom, dateTo } = event;
  const { isMobile } = useWindowDimensions();
  const startDate = moment().format('YYYY-MM-DD');
  const token = localStorage.getItem('token');
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { user } = jwt(token);

  const [name, setName] = React.useState('');

  /**
   * date selection
   */
  const [range, setRange] = React.useState({
    start: parseDate(startDate),
    end: parseDate(startDate),
  });

  const [icon, setIcon] = React.useState(null);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box
        sx={{
          position: 'absolute' as const,
          top: isMobile ? 0 : '50%',
          left: isMobile ? 0 : '50%',
          transform: isMobile ? 'unset' : 'translate(-50%, -50%)',
          //   bgcolor: 'white',
          border: '2px solid #ffffff0',
          //   boxShadow: 24,
          pt: isMobile ? 'unset' : 2,
          px: isMobile ? 'unset' : 4,
          pb: isMobile ? 'unset' : 3,
          borderRadius: isMobile ? 'unset' : 2,
          padding: isMobile ? 'unset' : 2,
          height: isMobile ? '100%' : '100vh',
          width: isMobile ? '100%' : '70%',
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            borderRadius: 10,
            height: '80%',
            position: 'relative',
            backgroundColor: 'white',
            overflow: 'hidden',
            boxShadow: `rgba(0, 0, 0, 0.35) 0px 5px 15px`,
          }}
        >
          <SidePanel>
            <h1
              style={{
                paddingLeft: 12,
                paddingRight: 12,
                color: 'black',
                fontSize: 50,
              }}
            >
              {title.length > 6 ? `${title.substring(0, 6)}...` : title}
            </h1>
            <p
              style={{
                fontSize: 14,
              }}
            >
              {dateTo === dateFrom ? (
                <div>{moment(dateFrom).format('ll')}</div>
              ) : (
                <div>
                  {moment(dateFrom).format('MMM Do')} -{' '}
                  {moment(dateTo).format('MMM Do, YYYY')}
                </div>
              )}
            </p>
          </SidePanel>
          <div
            style={{
              width: '100%',
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            <TaskList eventId={event.id} dateFrom={dateFrom} dateTo={dateTo} />
            <div
              style={{
                position: 'absolute',
                bottom: 20,
                right: 20,
                display: 'flex',
              }}
            >
              <ButtonOutline
                onClick={() => onClose()}
                $disabled={false}
                style={{
                  marginRight: 8,
                }}
              >
                <div>Close</div>
              </ButtonOutline>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
}
