import React from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import DeviceQuery from '../Utilities/DeviceQuery';
import useWindowDimensions from '../Utilities/WindowDetection';

type Props = {
  url: string;
  next?: () => void;
  prev?: () => void;
};

const Crop = styled.div`
  width: 100%;
  z-index: 9999;
  clip-path: inset(60px 0px 60px 0);
  @media (${DeviceQuery.mobile}) {
    clip-path: inset(0 0 0 0);
    position: unset;
  }
`;
export default function VideoPlayer({ url, next, prev }: Props) {
  const { isMobile } = useWindowDimensions();

  const [onEnd, setOnEnd] = React.useState(false);
  const [timeLeft, setTimeLeft] = React.useState(5);

  const height = isMobile ? '50vh' : '75vh';
  const width = '100%';
  const [muted, setMuted] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (!onEnd) {
      return;
    }
    if (timeLeft === 0) {
      next();
    }
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  });

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setMuted(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const onEndModal = () => {
    if (!onEnd) {
      return null;
    }
    return (
      <div
        style={{
          border: `1px solid white`,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: `translateX(-50%) translateY(-50%)`,
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          borderRadius: 8,
          boxShadow: `rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px`,
          padding: 16,
          zIndex: 9999,
          height: 150,
          width: 250,
          backgroundColor: '#1d2127',
          color: 'white',
          fontSize: 18,
        }}
      >
        Next Reel Playing In
        <div
          style={{
            fontSize: 36,
            fontWeight: 'bold',
          }}
        >
          {timeLeft}
        </div>
      </div>
    );
  };
  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
      }}
    >
      {onEndModal()}

      <Crop>
        <ReactPlayer
          onEnded={() => setOnEnd(true)}
          config={{ youtube: { playerVars: { disablekb: 1 } } }}
          options={{
            soundcloud: {
              auto_play: true,
            },
          }}
          height={height}
          width={width}
          playing
          muted={muted}
          url={url}
        />
      </Crop>
    </div>
  );
}

VideoPlayer.defaultProps = {
  prev: () => {},
  next: () => {},
};
