import React from 'react';
import {
  faVimeo,
  faInstagram,
  faFacebook,
  faTwitter,
  faYoutube,
  faSoundcloud,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const Socials = [
  {
    name: 'vimeo',
    icon: faVimeo,
    url: 'https://www.vimeo.com',
  },
  {
    name: 'instagram',
    icon: faInstagram,
    url: 'https://www.instagram.com',
  },
  {
    name: 'facebook',
    icon: faFacebook,
    url: 'https://www.facebook.com',
  },
  {
    name: 'twitter',
    icon: faTwitter,
    url: 'https://www.twitter.com',
  },
  {
    name: 'youtube',
    icon: faYoutube,
    url: 'https://www.youtube.com',
  },
  { name: 'soundcloud', icon: faSoundcloud, url: 'www.soundcloud.com' },
];

const Container = styled.div``;
export default function Social() {
  const [onFocus, setOnFocus] = React.useState('');

  const openInNewTab = (url: string) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };
  const styles = {
    icon: {
      padding: 8.5,
      cursor: 'pointer',
      fontSize: 25,
    },
  };

  return (
    <div
      style={{
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 24,
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {Socials.map(({ name, icon, url }) => (
        <Container
          onClick={() => openInNewTab(url)}
          onMouseEnter={() => setOnFocus(name)}
          onMouseLeave={() => setOnFocus('')}
        >
          <FontAwesomeIcon
            icon={icon}
            style={{
              color: name === onFocus ? '#cd94c4' : 'white',
              ...styles.icon,
            }}
          />
        </Container>
      ))}
    </div>
  );
}
