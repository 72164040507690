import React from 'react';
import Text from '../../../Components/Text/BaseText';
import EaseIn from '../../../Components/EaseIn';

type Props = {
  profile: string;
  tab: number;
};
export default function About({ tab, profile }: Props) {
  return (
    <EaseIn>
      <Text
        style={{
          fontSize: 32,
          paddingBottom: 8,
          fontWeight: 120,
        }}
      >
        ABOUT US
      </Text>
      <Text
        style={{
          fontWeight: 300,
          fontSize: 18,
        }}
      >
        {profile}
      </Text>
    </EaseIn>
  );
}
