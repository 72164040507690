import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import Text from '../../../Components/Text/BaseText';
import EaseIn from '../../../Components/EaseIn';
import DeviceQuery from '../../../Utilities/DeviceQuery';
import { getReels } from '../../../Api/Calls';
import YoutubeModal from '../YoutubeModal';

const Image = styled.div.attrs((props: { image: string }) => props)`
  position: relative;
  margin: 4px;
  cursor: pointer;
  border-radius: 4px;
  z-index: 1;
  min-width: 290px;
  width: calc((70vw / 3) - 0.3rem);
  height: 300px;
  border: 1px solid #80808091;
  background-image: ${(props) => `url(${props.image})`};
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  background-size: cover;
  @media (${DeviceQuery.tablet}) {
    min-width: calc(100vw - 18rem);
    height: 300px;
  }
  @media (${DeviceQuery.mobile}) {
    min-width: calc(100vw - 2rem);
    height: 300px;
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media (${DeviceQuery.mobile}) {
    flex-direction: column;
  }
`;

const Content = styled.div`
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 2.5rem);
  @media (${DeviceQuery.tablet}) {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
  }
  @media (${DeviceQuery.mobile}) {
    overflow: unset;
    height: unset;
  }
`;

type Props = {
  id: number;
};

export default function Reels({ id }: Props) {
  const [loading, setLoading] = React.useState(true);
  const [reels, setReels] = React.useState([]);
  const [url, setUrl] = React.useState('');

  const getData = async () => {
    setLoading(true);
    const { rows } = await getReels(id);
    if (rows !== null && rows.length > 0) {
      setReels(rows);
    }

    setLoading(false);
  };

  React.useEffect(() => {
    getData();
  }, []);

  const youTubeIdFromLink = (url: string) =>
    url.match(
      /(?:https?:\/\/)?(?:www\.|m\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\/?\?v=|\/embed\/|\/)([^\s&\?\/\#]+)/,
    )[1];
  const { darkMode } = useSelector((state: any) => state.Config);
  if (loading) {
    return null;
  }

  if (!loading && reels.length === 0) {
    return (
      <EaseIn>
        <Text
          style={{
            fontSize: 32,
            paddingBottom: 8,
            fontWeight: 120,
          }}
        >
          REELS
        </Text>
        <Text
          style={{
            paddingBottom: 8,
          }}
        >
          No reels found
        </Text>
      </EaseIn>
    );
  }
  return (
    <EaseIn>
      <Text
        style={{
          fontSize: 32,
          paddingBottom: 8,
          fontWeight: 120,
        }}
      >
        REELS
      </Text>
      <YoutubeModal
        url={url}
        open={url.length > 0}
        onClose={() => setUrl('')}
      />

      <Text
        style={{
          fontWeight: 300,
          fontSize: 18,
        }}
      >
        <Container>
          <Content>
            {reels.map((reel: any) => (
              <Image
                image={`http://img.youtube.com/vi/${youTubeIdFromLink(
                  reel.url,
                )}/maxresdefault.jpg`}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    boxShadow: `rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px`,
                  }}
                >
                  <FontAwesomeIcon
                    onClick={() => setUrl(reel.url)}
                    style={{
                      cursor: 'pointer',
                      backgroundColor: 'grey',
                      padding: 16,
                      borderRadius: 4,
                      fontSize: 16,
                    }}
                    icon={faPlay}
                    color="white"
                  />
                </div>
              </Image>
            ))}
          </Content>
        </Container>
      </Text>
    </EaseIn>
  );
}
